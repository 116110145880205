import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ApiHttpService } from '../../_service/api-http.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-productform-admin',
  templateUrl: './productform-admin.component.html',
  styleUrls: ['./productform-admin.component.css']
})
export class ProductformAdminComponent implements OnInit {

  postParamFormData = {product_category_id:'',product_title:'',product_mrp:'',expiry_period:'',product_description:''};
  errorMsg = [];

  productCategoryList = [];
  getData:boolean = false;

  constructor(private apiHttp:ApiHttpService,private router: Router,
    private snackBar: MatSnackBar) { 
      this.getCategoryList();
    }

  ngOnInit() {
  }

  getCategoryList() {

    this.apiHttp.getProductCategoryList(this.postParamFormData)
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {

        if(data['status'] == 100) {
          this.productCategoryList = data['data'];
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }

    });
  }

  addProduct() {

    this.apiHttp.addProduct(this.postParamFormData)
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {

        if(data['status'] == 100) {

          this.snackBar.open("Product added successfully.!",'', {
            duration: 10000,
            horizontalPosition: 'center',
            verticalPosition : 'top'
          });

          this.router.navigate(['/admin/productlist']);

        } else if(data['status'] == 101) {
          console.log(data['data']);
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }

    });
  }
}
