import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';

import { ApiHttpService } from '../../_service/api-http.service';

@Component({
  selector: 'app-supplychainform-admin',
  templateUrl: './supplychainform-admin.component.html',
  styleUrls: ['./supplychainform-admin.component.css']
})
export class SupplychainformAdminComponent implements OnInit {
  
  userTypeDetails     = {};
  serviceProviderList = [];

  usertype_id     = '';
  hierarchy_level = '';

  getData:boolean = false;

  postParamFormData = {business_name:'',business_code:'',business_contact:'',business_email:'',business_pan_no:'',business_gst_no:'',business_address:'',business_pincode:'',fullname:'',mobile1:'',gender:'',email:'',pan_no:'',adhaar_no:'',username:'',passwd:'',service_provider:''};
  postParamUserType = {};
  postParamServiceProvider = {};
  service_provider: string[];

  constructor(private router: Router,
              private apiHttp:ApiHttpService,
              private route: ActivatedRoute,
              private snackBar: MatSnackBar) {

    this.usertype_id = this.route.snapshot.paramMap.get('usertype_id');
    this.postParamFormData['usertype_id'] = this.usertype_id;
    this.getUserTypeDetails();
  }

  ngOnInit() {}

  // GET USER TYPE DETAILS
  getUserTypeDetails(): void {

    this.postParamUserType['usertype_id'] = this.usertype_id;
    this.apiHttp.getSalesSupplyChainType(this.postParamUserType)
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {

        if(data['status'] == 100) {
        
          this.userTypeDetails = data['data'];
          this.hierarchy_level = this.userTypeDetails['hierarchy_level'];

         
          this.getServiceProviderList();
          this.getData = true;

        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }

      }
    });
  }

  // GET USER TYPE DETAILS
  getServiceProviderList(): void {

    this.postParamServiceProvider['hierarchy_level'] = this.hierarchy_level;
    this.apiHttp.getServiceProviderList(this.postParamServiceProvider)
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {
        if(data['status'] == 100) {
          this.serviceProviderList = data['data'];
          this.getData = true;
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }

    });
  }

  // HTTP ADD SUPPLY USER TO SERVER
  httpAddSupplyUser() {

    console.log(this.postParamFormData);
    // if
    if(this.hierarchy_level == '1' )
    {
      this.service_provider = ["1"];
      console.log("List:"+this.serviceProviderList);

      this.apiHttp.addSupplyUser(this.postParamFormData)
      .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {

        if(data['status'] == 100) {

          this.openSnackBar("User added successfully.");
          let rUrl = '/admin/supplylist/'+this.usertype_id;
          this.router.navigateByUrl(rUrl);

        } else if(data['status'] == 101) {
          console.log(data['data'])
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }

      }
    });
  }
}

  openSnackBar(message: string) {
    this.snackBar.open(message,'', {
      duration: 10000,
      horizontalPosition: 'center',
      verticalPosition : 'top'
    });
  }

}
