import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
export interface DialogData {
  
}
import { Router, ActivatedRoute } from '@angular/router';
import { ApiHttpService } from '../../../_service/api-http.service';


@Component({
  selector: 'app-supply-profile',
  templateUrl: './supply-profile.component.html'
})
export class SupplyProfileComponent implements OnInit {
 

  businessInfo      = {};
  bankDetails       = {};
  ownerInfo         = {};
  headerInfo        = {};

  member_id       = '';
  getData:boolean = false;

  postParamUserProfile = {};
  hierarchyLevel: '';

  constructor(private router: Router,
    private apiHttp:ApiHttpService,
    private route: ActivatedRoute,
    public dialog: MatDialog) { 

    this.member_id = this.route.snapshot.paramMap.get('member_id');
    this.getUserProfile();
  }

  ngOnInit() {
  }

  // GET USER TYPE DETAILS
  getUserProfile(): void {

    this.postParamUserProfile['member_id'] = this.member_id;
    this.apiHttp.getSupplyProfile(this.postParamUserProfile)
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {

        if(data['status'] == 100) {

          this.headerInfo   = data['data']['headerInfo'];
          this.businessInfo = data['data']['businessInfo'];
          this.bankDetails  = data['data']['bankDetails'];
          this.ownerInfo    = data['data']['ownerInfo'];
          this.hierarchyLevel = this.headerInfo['hierarchyLevel'];

          this.getData      = true;

        } else if(data['status'] == 101) {
          this.router.navigate(['/admin/supplylist/1']);
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }

    });
  }
  
  // update business info
  openUpdateBusinessInfoSupplyDialog(): void {
    const dialogRef = this.dialog.open(openUpdateBusinessInfoSupplyDialog, {
      width: '800px',
      data:{businessInfo:this.businessInfo,member_id:this.member_id}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getUserProfile();
    });
  }

  openEditLoginInfoSupplyDialog(): void {
    const dialogRef = this.dialog.open(openEditLoginInfoSupplyDialog, {
      width: '800px',
      data:{username:this.headerInfo['username'],member_id:this.member_id}
    });

    dialogRef.afterClosed().subscribe(result => {
       this.getUserProfile();
    });
  }

  openUpdateBaSicInfoSupplyDialog(): void {
    const dialogRef = this.dialog.open(openUpdateBaSicInfoSupplyDialog, {
      width: '800px',
      data:{ownerInfo:this.ownerInfo,member_id:this.member_id}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getUserProfile();
    });
  }

  openEditBankDetailsSupplyDialog(): void {
    const dialogRef = this.dialog.open(openEditBankDetailsSupplyDialog, {
      width: '800px',
      data:{bankDetails:this.bankDetails,member_id:this.member_id}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getUserProfile();
    });
  }

  openDeleteSupplyDialog(): void {
    const dialogRef = this.dialog.open(openDeleteSupplyDialog, {
      width: '800px',
    });
  }

  openUpdateServiceProviderSupplyDialog(): void {
    const dialogRef = this.dialog.open(openUpdateServiceProviderSupplyDialog, {
      width: '500px',
      data:{hierarchy_level:this.hierarchyLevel,member_id:this.member_id}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getUserProfile();
    });
  }
}

// UPDATE BUSINESS INFO DIALOG
@Component({
  selector: 'dialog-update-business-info',
  templateUrl: 'dialog-update-business-info.html',
})
export class openUpdateBusinessInfoSupplyDialog {

  postParamFormData = {business_code:'',business_name:'',business_email:'',business_contact:'',business_pincode:'',business_pan_no:'',business_gst_no:'',business_address:''};
  member_id = '';
  errorMsg  = [];

  constructor(
    public dialogRef: MatDialogRef<openUpdateBusinessInfoSupplyDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private apiHttp:ApiHttpService,private router: Router,
    private snackBar: MatSnackBar) {

      this.postParamFormData = data['businessInfo']; 
      this.member_id         = data['member_id'];
  }

  updateBusinessInfo() {

    this.postParamFormData['id'] = this.member_id;
    this.apiHttp.updateSupplyBusinessInfo(this.postParamFormData)
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {

        if(data['status'] == 100) {

          this.snackBar.open("Information update successfully.!",'', {
            duration: 10000,
            horizontalPosition: 'center',
            verticalPosition : 'top'
          });
          this.dialogRef.close();
          this.router.navigate(['/admin/supplyprofile/'+this.member_id]);
        } else if(data['status'] == 101) {
          this.errorMsg = data['data'];           
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }

    });
  }
}

// EDIT LOGIN INFO DIALOG
@Component({
  selector: 'dialog-edit-login-info',
  templateUrl: '../../edit-popup/dialog-edit-login-info.html',
})
export class openEditLoginInfoSupplyDialog {

  postParam = {username:'',member_id:'',passwd:''};
  errorMsg  = {length:''}; 

  constructor(
    public dialogRef: MatDialogRef<openEditLoginInfoSupplyDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private apiHttp:ApiHttpService,private router: Router,
    private snackBar: MatSnackBar) {

    this.postParam['username']  = data['username'];
    this.postParam['member_id'] = data['member_id'];
  }

  updateLoginInfo() {
    this.apiHttp.updateUserLogin(this.postParam)
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {

        if(data['status'] == 100) {

          this.snackBar.open("Information update successfully.!",'', {
            duration: 10000,
            horizontalPosition: 'center',
            verticalPosition : 'top'
          });
          this.dialogRef.close();
          this.router.navigate(['/admin/supplyprofile/'+this.postParam['member_id']]);
        } else if(data['status'] == 101) {
          this.errorMsg = data['data'];           
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }

    });
  }
}

// UPDATE BASIC INFO 
@Component({
  selector: 'dialog-update-basic-info',
  templateUrl: 'dialog-update-basic-info.html',
})
export class openUpdateBaSicInfoSupplyDialog {

  postParamFormData = {fullname:'',gender:'',mobile1:'',email:'',adhaar_no:'',pan_no:''};
  member_id = '';
  errorMsg  = [];

  constructor(
    public dialogRef: MatDialogRef<openUpdateBaSicInfoSupplyDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private apiHttp:ApiHttpService,private router: Router,
    private snackBar: MatSnackBar) {

      this.postParamFormData = data['ownerInfo']; 
      this.member_id         = data['member_id'];
    }

    updateBusinessInfo() {

      this.postParamFormData['id'] = this.member_id;
      this.apiHttp.updateSupplyOwnerInfo(this.postParamFormData)
      .subscribe(data=> {
  
        let keys = Object.keys(data);
        if(keys.length != 0) {
  
          if(data['status'] == 100) {
  
            this.snackBar.open("Information update successfully.!",'', {
              duration: 10000,
              horizontalPosition: 'center',
              verticalPosition : 'top'
            });

            this.dialogRef.close();
            this.router.navigate(['/admin/supplyprofile/'+this.member_id]);
          } else if(data['status'] == 101) {
            this.errorMsg = data['data'];           
          } else if(data['status'] == 103) {
            this.apiHttp.logoutUser();
          }
        }
  
      });
    }
}

// EDIT BANK DETAILS DIALOG
@Component({
  selector: 'dialog-edit-bank-details',
  templateUrl: '../../edit-popup/dialog-edit-bank-details.html',
})

export class openEditBankDetailsSupplyDialog {

  postParam = {bank_name:'',account_name:'',account_number:'',ifsc_code:''};
  member_id = '';
  errorMsg  = [];

  constructor(
    public dialogRef: MatDialogRef<openEditBankDetailsSupplyDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private apiHttp:ApiHttpService,private router: Router,
    private snackBar: MatSnackBar) {

      this.postParam = data['bankDetails']; 
      this.member_id         = data['member_id'];
    }

    updateBankDetailsInfo() {

      this.postParam['id'] = this.member_id;
      this.apiHttp.updateBankDetails(this.postParam)
      .subscribe(data=> {
  
        let keys = Object.keys(data);
        if(keys.length != 0) {
  
          if(data['status'] == 100) {
  
            this.snackBar.open("Information update successfully.!",'', {
              duration: 10000,
              horizontalPosition: 'center',
              verticalPosition : 'top'
            });

            this.dialogRef.close();

          } else if(data['status'] == 101) {
            this.errorMsg = data['data'];           
          } else if(data['status'] == 103) {
            this.apiHttp.logoutUser();
          }
        }
  
      });
    }
}

// UPDATE TEAM(ASSIGNMENT) MEMBER
@Component({
  selector: 'dialog-update-team-member',
  templateUrl: '../../edit-popup/dialog-update-team-member.html',
})
export class openUpdateServiceProviderSupplyDialog {

  updateTitle = "UPDATE SERVICE PROVIDER";
  placeholder = "Select Service Provider";

  postParamServiceProvider = {'service_provider':'1'};
  hierarchy_level     = '';
  member_id           = '';
  serviceProviderList = [];
  getData:boolean     = false;
  postParamServiceProviderList = {};

  constructor(
    public dialogRef: MatDialogRef<openUpdateServiceProviderSupplyDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private apiHttp:ApiHttpService,private snackBar: MatSnackBar
    ) {
      this.hierarchy_level = data['hierarchy_level'];
      this.getServiceProviderList();
      this.member_id = data['member_id'];
  }
  
  // GET USER TYPE DETAILS
  getServiceProviderList(): void {

    this.postParamServiceProviderList['hierarchy_level'] = this.hierarchy_level;
    this.apiHttp.getServiceProviderList(this.postParamServiceProviderList)
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {
        if(data['status'] == 100) {
          this.serviceProviderList = data['data'];
          this.getData = true;
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }

    });
  }

  updateServiceProvider(){

    this.postParamServiceProvider['id'] = this.member_id;
    this.apiHttp.updateServiceProvider(this.postParamServiceProvider)
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {
        if(data['status'] == 100) {

          this.snackBar.open("Information update successfully.!",'', {
            duration: 10000,
            horizontalPosition: 'center',
            verticalPosition : 'top'
          });

          this.dialogRef.close();
        
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }

    });

  }
}

@Component({
  selector: 'dialog-delete-supply',
  templateUrl: '../../edit-popup/dialog-delete.html',
})

export class openDeleteSupplyDialog {

  constructor(
    public dialogRef: MatDialogRef<openDeleteSupplyDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}
    
  deleteUserType(){}
    
  closeDialogBox() {
    this.dialogRef.close();
  }
}
