import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-sales',
  templateUrl: './account-sales.component.html'
})
export class AccountSalesComponent implements OnInit {

  headerInfo   = {username:"pgfepale"};

  basicInfo    = {name:"Prashant Fepale",mobile:"9049494771",app_version:"1.2",contact:"0712-2774770",email:"Maharashtra",city:"Nagpur",state:"Maharashtra",address:"2, Lane Number 2, Laxman Nagar, Nagpur, Maharashtra 411045"};

timelinelist = [  {time:"05 min ago",activity:"Logged In", date:"11 Sep 2018"},
                  {time:"25 min ago",activity:"Visit to R.K. Enterprises", date:"11 Sep 2018"},
                  {time:"40 min ago",activity:"Registered new retailer Sai kirana", date:"11 Sep 2018"},
                  {time:"45 min ago",activity:"Order Booked for Sai kirana", date:"11 Sep 2018"},
                  {time:"55 min ago",activity:"Order Booked for Bhagwati kirana", date:"11 Sep 2018"},
                  {time:"60 min ago",activity:"Registered new retailer Ramesh kirana two", date:"11 Sep 2018"},
                  {time:"25 min ago",activity:"Visit to R.K. Enterprises", date:"11 Sep 2018"},
                  {time:"40 min ago",activity:"Registered new retailer Sai kirana", date:"11 Sep 2018"},
                  {time:"45 min ago",activity:"Order Booked for Sai kirana", date:"11 Sep 2018"},
                  {time:"55 min ago",activity:"Order Booked for Bhagwati kirana", date:"11 Sep 2018"},
                  {time:"60 min ago",activity:"Registered new retailer Ramesh kirana two", date:"11 Sep 2018"},
                  {time:"45 min ago",activity:"Order Booked for Ramesh kirana two", date:"11 Sep 2018"},
                  {time:"55 min ago",activity:"Visited Ramesh kirana two -(Stock Available)", date:"11 Sep 2018"},
                  {time:"60 min ago",activity:"Order Booked for Laxmi kirana", date:"11 Sep 2018"}
 ];
  constructor() { }

  ngOnInit() {
  }

}
