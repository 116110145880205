import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiHttpService } from '../../../_service/api-http.service';
 
@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html'
})
export class UserListComponent implements OnInit {

  salesUserType    = [];
  userTypeDetails  = {};
  saleslist        = [];

  usertype_id       = '';
  getData:boolean   = false;

  postParamUserTypeList     = {"usertype":"SALES"};
  postParamUserTypeDetails  = {};
  postParamSupplyList       = {};
  loggedInUsertype = '';

  userlist = [  
    {photo:"../assets/images/girl.png",  user:{designation:"Regional Sales Manager", name:"Anjali Bodkhe"},  city:"Nagpur",  state:"Maharashtra", mobile:"8625810892", email:"bodkheanjali@gmail.com", lastactive:"347, Chapru Nagar, Bagadganj, Nagpur, Maharashtra 440008,India"},
    {photo:"../assets/images/girl1.png",  user:{designation:"Area Sales Manager", name:"Shweta Shambhargade"}, city:"Amravati",  state:"Maharashtra", mobile:"9561275486", email:"shambhargade.shweta@gmail.com", lastactive:"8-11-409, Sharif Colony, Jaswantpura, Aurangabad, Maharashtra 431003, India"},
    {photo:"../assets/images/boy.png",  user:{designation:"Sales Officer", name:"Prashant Fepale"}, city:"Badnera",state:"Maharashtra", mobile:"9049494771", email:"pgfepale@gmail.com", lastactive:"2, Najafgarh Rd, Block D, Mansa Ram Park, Uttam Nagar,Delhi, 110059, India"},
    {photo:"../assets/images/girl.png",  user:{designation:"Sales Representative", name:"Neha Patil"},  city:"Badnera",  state:"Maharashtra", mobile:"9766826182", email:"neha.patil@gmail.com", lastactive:"347, Chapru Nagar, Bagadganj, Nagpur, Maharashtra 440008,India"},
    {photo:"../assets/images/girl1.png",  user:{designation:"Regional Sales Manager", name:"Komal Borkar"},city:"Mumbai",state:"Maharashtra", mobile:"8856979605", email:"kdb276@gmail.com", lastactive:"308, Tulikrupa, Garoba Maidan, Kapse Chowk, Nagpur 440008"},
    {photo:"../assets/images/boy1.png",  user:{designation:"Area Sales Manager", name:"Parth Fepale"},city:"Mumbai", state:"Maharashtra", mobile:"9049494771", email:"ppfepale@gmail.com", lastactive:"347, Chapru Nagar, Bagadganj, Nagpur, Maharashtra 440008,India"}
    
  ];

  constructor(private router: Router,
    private apiHttp:ApiHttpService,
    private route: ActivatedRoute) { 

    this.loggedInUsertype = localStorage.getItem('sales_supply');
    this.usertype_id = this.route.snapshot.paramMap.get('usertype_id');
    if(this.usertype_id == null) {
      this.usertype_id = "1";
    }
    
    this.getListOfSalesComponent();    
  }

  ngOnInit() {
  }

  goToAddSalesUser(id) {
    let rUrl = "admin/addsalesform/"+id;
    this.router.navigate([rUrl]);
  }

  goToUsertypeCategoty(id) {
    this.usertype_id = id;
    this.getData = false;

    this.getUserTypeDetails();
    this.getSalesList();

    let rUrl = "admin/saleslist/"+id;
    this.router.navigate([rUrl]);
  }

  goToUserProfile(id) {
    // let rURL = "/admin/salesprofile/"+id
    // this.router.navigate([rURL]);
    let rURL;
    if(this.loggedInUsertype == "ADMIN"){
      rURL = "/admin/salesprofile/"+id;
    } else if(this.loggedInUsertype == "SUPPLY") {
      rURL = "/supply/salesprofile/"+id;
    } else{
      rURL = "/sales/salesprofile/"+id;
    }

    this.router.navigateByUrl(rURL);
  }

  getSalesList(): void {
    this.postParamSupplyList['usertype_id'] = this.usertype_id;
    this.apiHttp.getSalesList(this.postParamSupplyList)
      .subscribe(data=> {

        let keys = Object.keys(data);
        if(keys.length != 0) {
          if(data['status'] == 100) {
            
            this.saleslist = data['data'];
            this.getData   = true;

          } else if(data['status'] == 103) {
            this.apiHttp.logoutUser();
          }
        }
    });
  }

  getUserTypeDetails(): void {
     
    this.postParamUserTypeDetails['usertype_id'] = this.usertype_id;
    this.apiHttp.getSalesSupplyChainType(this.postParamUserTypeDetails)
    .subscribe(data=> {
      let keys = Object.keys(data);
      if(keys.length != 0) {
        if(data['status'] == 100) {
          this.userTypeDetails = data['data'];
        } else if(data['status'] == 101) {
          let rUrl = "admin/userlist";
          this.router.navigate([rUrl]);
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }
    });
  }

  // GET LIST OF ALL USER TYPE
  getListOfSalesComponent() {
    this.apiHttp.getSalesSupplyChainType(this.postParamUserTypeList)
    .subscribe(data=> {
      let keys = Object.keys(data);
      if(keys.length != 0) {
        if(data['status'] == 100) {
          
          this.salesUserType = data['data']['salesList'];
          this.usertype_id = this.salesUserType[0].id; 
          
          this.getUserTypeDetails();
          this.getSalesList();
          
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }
    });
  }

  searchList(e:any){

    let searchString = e.target.value;
    if(searchString.length > 2)
    {
      this.postParamSupplyList['usertype_id'] = this.usertype_id;
      this.postParamSupplyList['searchString'] = searchString;

      this.apiHttp.getSalesList(this.postParamSupplyList)
        .subscribe(data=> {
  
          let keys = Object.keys(data);
          if(keys.length != 0) {
            if(data['status'] == 100) {
              
              this.saleslist = data['data'];
              this.getData   = true;
  
            } else if(data['status'] == 103) {
              this.apiHttp.logoutUser();
            }
          }
      });
    }
  }

}
