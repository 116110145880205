import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-supply-purchase-history',
  templateUrl: './supply-purchase-history.component.html'
})
export class SupplyPurchaseHistoryComponent implements OnInit {
  public barChartOptions:any = {
    scaleShowVerticalLines: false,
    responsive: true
  };

  public barChartLabels:string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  public barChartType:string = 'bar';
  public barChartLegend:boolean = true;

  public barChartData:any[] = [
    {data: [65, 49, 100, 81, 36, 55, 50, 65, 49, 100, 81, 36,], label: 'Weekly Sales'}
  ];
 
  public chartColors: any[] = [
    { 
      backgroundColor:["#f88a81", "#ffe8a2", "#f88a81", "#ffe8a2", "#f88a81", '#ffe8a2', "#f88a81", "#ffe8a2", "#f88a81", "#ffe8a2", "#f88a81", '#ffe8a2',] 
    }];

 // events
 public chartClicked(e:any):void {
  console.log(e);
}

public chartHovered(e:any):void {
  console.log(e);
}


  constructor() { }

  ngOnInit() {
  }

}
