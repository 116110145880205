import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiHttpService } from '../../../../_service/api-http.service';

export interface DialogData {
}

@Component({
  selector: 'app-user-profile-header',
  templateUrl: './user-profile-header.component.html'
})
export class UserProfileHeaderComponent implements OnInit {
 
  headerInfo           = {photo:'',fullname:'',mobile1:'',usertype:'',status:'',lastActive:'',id:'',headerSales:{monthlySales:'0.0',todaysSales:'0.0'}};
  postParamUserProfile = {};
  member_id            = '';
  getData:boolean      = false;

  constructor(private router: Router,
              private apiHttp:ApiHttpService,
              private route: ActivatedRoute,
              public dialog: MatDialog) { 
              
              this.member_id = this.route.snapshot.paramMap.get('id');
              this.getUserProfileHeader();
      }

  ngOnInit() {
  }

  // UPLOAD PHOTO
  openPhotoUploadDialog(): void {
    const dialogRef = this.dialog.open(openPhotoUploadDialog, {
      width: '600px',
      data:{member_id:this.member_id}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getUserProfileHeader();
    });
  }

  // GET USER TYPE DETAILS
  getUserProfileHeader(): void {

    this.postParamUserProfile['member_id'] = this.member_id;
    this.apiHttp.profileHeaderSalesUser(this.postParamUserProfile)
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {

        if(data['status'] == 100) {
          
          this.headerInfo = data['data']['headerInfo'];
          this.getData    = true;

        } else if(data['status'] == 101) {
          this.router.navigate(['/admin/saleslist/1']);
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }
    });
  }
}

// UPLOAD PHOTO DIALOG
@Component({
  selector: 'dialog-photo-upload',
  templateUrl: '../../../edit-popup/dialog-photo-upload.html',
})
export class openPhotoUploadDialog {
  [x: string]: any;

  uploadTitle = "UPLOAD LOGO";

  member_id = "";
  errorMsg  = "";
  userPhoto:File;
  uploadBtn = true;

  constructor(
    public dialogRef: MatDialogRef<openPhotoUploadDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private apiHttp:ApiHttpService, private snackBar: MatSnackBar
    ) {
      this.member_id = data['member_id'];
    }
   
    getSelectedFile($event) {
      this.userPhoto = $event.target.files[0];  
      this.uploadBtn = false;
    }  
    uploadFile() {
      this.uploadBtn = true;
      const _formData = new FormData();
  
      _formData.append('token',localStorage.getItem('token'));  
      _formData.append('user_id',localStorage.getItem('user_id'));  
      _formData.append('member_id',this.member_id);  
  
      _formData.append('file', this.userPhoto, this.userPhoto.name);  
  
      this.apiHttp.uploadUserPhoto(_formData)
      .subscribe(data=> {
  
        let keys = Object.keys(data);
        if(keys.length != 0) {
  
          if(data['status'] == 100) {
  
            this.snackBar.open("Upload successfully.!",'', {
              duration: 10000,
              horizontalPosition: 'center',
              verticalPosition : 'top'
            });
  
            this.dialogRef.close();
            // this.router.navigate(['/admin/salesprofile/'+this.member_id]);
            
          } else if(data['status'] == 101) {
            this.errorMsg = data['data'];           
          }
        }
  
      });
    }
 
  // handleInputChange(event) {
  //     console.log(event);
  //     this.image = event.target.files[0];

  //     var pattern = /image-*/;

  //     if (!this.image.type.match(pattern)) {
  //         console.log('File is not an image');
  //         return;
  //     }
  //     this.post.postPhoto = this.image;

  //     console.log('image :' + this.image);
  // }
  // uploadPhoto() {
  //   const url = "http://localhost/api/sales-supply-backend//profilePhotoUpload"; 
  //   let files = this.elem.nativeElement.querySelector("#photo").files;
  //   const formData = new FormData();
  //   let file =files[0];
  //   formData.append('data', JSON.stringify({
  //       test: 'test'
  //   }));
  //   formData.append('photo', file);
  //   const headers = new HttpHeaders().set('Content-Type', []);
  //   this.apiHttp.post(url, formData, {
  //       headers,
  //       responseType: 'text'
  //   });
  //   console.log("Looks good");
  // }  

}
