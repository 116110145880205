import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiHttpService } from '../../../_service/api-http.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html'
})
export class ProductListComponent implements OnInit {

  productList = [];
  getData:boolean = false;
  loggedInUsertype = '';
  postParamSupplyList       = {};
  postParamData       = {};

  constructor(private router: Router,private apiHttp:ApiHttpService) { 
    this.getProductList();
    this.loggedInUsertype = localStorage.getItem('sales_supply');
  }

  ngOnInit() {
  }

  goToProductProfile(product_id) {
    let rURL;
    if(this.loggedInUsertype == "ADMIN"){
      rURL = "/admin/productprofile/"+product_id;
    } else if(this.loggedInUsertype == "SUPPLY") {
      rURL = "/supply/productprofile/"+product_id;
    } else if(this.loggedInUsertype == "SALES"){
      rURL = "/sales/productprofile/"+product_id;
    }

    this.router.navigateByUrl(rURL);
  };

  getProductList() {
    this.apiHttp.getProductList({})
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {

        if(data['status'] == 100) {
          this.productList = data['data'];
          this.getData = true;
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }

    });
  }



  searchList(e:any){

    let searchString = e.target.value;
    if(searchString.length > 2)
    {
      
      this.postParamData['searchString'] = searchString;
      this.apiHttp.getProductList(this.postParamData)
      .subscribe(data=> {
  
        let keys = Object.keys(data);
        if(keys.length != 0) {
  
          if(data['status'] == 100) {
            this.productList = data['data'];
          } else if(data['status'] == 103) {
            this.apiHttp.logoutUser();
          }
        }
  
      });
    }
  }
}
