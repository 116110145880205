import { Component, OnInit, Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { ApiHttpService } from 'src/app/_service/api-http.service';
import { Constants } from 'src/app/_service/constants';
export interface DialogData {
}
@Component({
  selector: 'app-rate-category',
  templateUrl: './rate-category.component.html',
  styleUrls: ['./rate-category.component.css']
})
export class RateCategoryComponent implements OnInit {

  rateCategoryList = [];
  productRateList  = [];

  postParamProductRate = {};
  rate_category_title  = "";
  rate_category_id     = "";
  categorySelected     = false;   

  constructor(public dialog: MatDialog,
    private apiHttp:ApiHttpService,private apilist:Constants,
    private router: Router) {
      this.getRateCategoryList();
  }

  ngOnInit() {
  }

  openAddRateCategoryDialog(): void {
    const dialogRef = this.dialog.open(openAddRateCategoryDialog, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getRateCategoryList();
    });
  }

  openEditRateList(id): void {
    const dialogRef = this.dialog.open(openEditRateList, {
      width: '600px',
      data:{"id":id}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getRateListByCategory(this.rate_category_title,this.rate_category_id);
    });
  }

  // upload excel pop-up
  openUploadExcel(): void {
    const dialogRef = this.dialog.open(openUploadExcel, {
      width: '600px',
      data:{rate_category_id:this.rate_category_id}
    });
  }

  getRateListByCategory(rate_category_id,rate_category_title) {

    this.categorySelected = true; 

    this.rate_category_id = rate_category_id;
    this.rate_category_title = rate_category_title;

    this.postParamProductRate['rate_category_id'] = rate_category_id;
    this.apiHttp.getRateListByCategory(this.postParamProductRate)
      .subscribe(data=> {
  
        let keys = Object.keys(data);
        if(keys.length != 0) {
  
          if(data['status'] == 100) {
            this.productRateList = data['data'];
          } else if(data['status'] == 103) {
            this.apiHttp.logoutUser();
          }
        }

    });

  }

  getRateCategoryList() {

    this.apiHttp.getRateCategoryList({})
      .subscribe(data=> {
  
        let keys = Object.keys(data);
        if(keys.length != 0) {
  
          if(data['status'] == 100) {
            this.rateCategoryList = data['data'];
          } else if(data['status'] == 103) {
            this.apiHttp.logoutUser();
          }
        }

    });
  }

  downLoadSampleFile() {
    window.open(
      this.apilist.downloadRateSampleExcel,
      '_blank'
    );
  }

}

// ADD RATE CATEGORY
@Component({
  selector: 'dialog-add-rate-category',
  templateUrl: 'dialog-add-rate-category.html',
})
export class openAddRateCategoryDialog {

  postParamAddData = {rate_category_title:''};
  
  constructor(
    public dialogRef: MatDialogRef<openAddRateCategoryDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private apiHttp:ApiHttpService,private router: Router,
    private snackBar: MatSnackBar
  ) {}

  addRateCategory() {

    this.apiHttp.addRateCategory(this.postParamAddData)
      .subscribe(data=> {
  
        let keys = Object.keys(data);
        if(keys.length != 0) {
  
          if(data['status'] == 100) {
  
            this.snackBar.open("Rate category added successfully.!",'', {
              duration: 10000,
              horizontalPosition: 'center',
              verticalPosition : 'top'
            });

            this.dialogRef.close();
            this.router.navigate(["/admin/ratecategory"]);
            
          } else if(data['status'] == 103) {
            this.apiHttp.logoutUser();
          }
        }
    });  

  }  
}

// EDIT RATE LIST
@Component({
  selector: 'dialog-edit-rate-list',
  templateUrl: 'dialog-edit-rate-list.html',
})
export class openEditRateList {

  postParamEdit = {id:''};
  sell_rate = '';
  constructor(
    public dialogRef: MatDialogRef<openEditRateList>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public apiHttp:ApiHttpService, private snackBar: MatSnackBar
    ) {
      this.postParamEdit['id'] = data['id'];
  }

  updateRateChange() {

      this.apiHttp.updateRateListSingle(this.postParamEdit)
        .subscribe(data=> {
    
          let keys = Object.keys(data);
          if(keys.length != 0) {
    
            if(data['status'] == 100) {
    
              this.snackBar.open("Rate updated successfully.!",'', {
                duration: 10000,
                horizontalPosition: 'center',
                verticalPosition : 'top'
              });
  
              this.dialogRef.close();
            } else if(data['status'] == 103) {
              this.apiHttp.logoutUser();
            }
          }
      });  
  

  }

}


// UPLOAD EXCEL
@Component({
  selector: 'dialog-photo-upload',
  templateUrl: '../../edit-popup/dialog-photo-upload.html',
})
export class openUploadExcel {

  uploadTitle      = "UPLOAD EXCEL";
  rate_category_id = "";
  errorMsg         = "";
  uploadBtn        = true;
  rateExcel:File;

  constructor(
    public dialogRef: MatDialogRef<openUploadExcel>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private router: Router,
    private apiHttp:ApiHttpService, private snackBar: MatSnackBar
    ) {
      this.rate_category_id = data['rate_category_id'];
  }

  getSelectedFile($event) {
    this.rateExcel = $event.target.files[0];  
    this.uploadBtn = false;
  }  

  uploadFile() {
    this.uploadBtn = true;
    const _formData = new FormData();

    _formData.append('token',localStorage.getItem('token'));  
    _formData.append('user_id',localStorage.getItem('user_id'));  
    _formData.append('rate_category_id',this.rate_category_id);  

    _formData.append('file', this.rateExcel, this.rateExcel.name);  

    this.apiHttp.uploadExcelPriceList(_formData)
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {

        if(data['status'] == 100) {

          this.snackBar.open("Price list update successfully.!",'', {
            duration: 10000,
            horizontalPosition: 'center',
            verticalPosition : 'top'
          });
          
          console.log("Responce Data:"+data['data']);

          this.dialogRef.close();

        } else if(data['status'] == 101) {
          this.errorMsg = data['data'];           
        }
      }

    });
  }

}
