import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ApiHttpService } from 'src/app/_service/api-http.service';
import { Router } from '@angular/router';
export interface DialogData {
  
}
@Component({
  selector: 'app-account-admin',
  templateUrl: './account-admin.component.html'
})
export class AccountAdminComponent implements OnInit {

  headerInfo   = {username:"pgfepale"};

  businessInfo = {business_code:"25175",business_name:"Sreyah Technologies",business_email:"support@sreyah.co.in",business_contact:"0712-2774770",business_address:"2, Lane Number 2, Laxman Nagar, Nagpur, Maharashtra 411045",business_city:"Nagpur",business_state:"Maharashtra",business_pincode:"420008",business_pan_no:"ABHJPF1160E",gst_no:"SD15478D56652"};

  ownerInfo    = {fullname:"Prashant Fepale",gender:"Male",mobile1:"9049494771",email:"pgfepale@gmail.com",pan_no:"BDCF2584F",adhaar_no:"874585416012"};

  bankDetails  = {bank_name:"State Bank of India",account_name:"Savings Account",account_number:"49401503779",ifsc_code:"SBI0215001"};

  member_id       = '';
  
  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }

  openEditLoginInfoAdminAccountDialog(): void {
    const dialogRef = this.dialog.open(openEditLoginInfoAdminAccountDialog, {
      width: '800px',
      data:{username:this.headerInfo['username'],member_id:this.member_id}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  openPhotoUploadAdminAccountDialog(): void {
    const dialogRef = this.dialog.open(openPhotoUploadAdminAccountDialog, {
      width: '500px',
    });
  }

}

// UPLOAD PHOTO DIALOG
@Component({
  selector: 'dialog-photo-upload',
  templateUrl: '../../_component/edit-popup/dialog-photo-upload.html',
})
export class openPhotoUploadAdminAccountDialog {

  userTypeData = {"user_sales_supply":"SUPPLY","login_access":false};
  member_id = "";
  errorMsg = ''
  uploadBtn = true;
  businessLogo:File;
  uploadTitle = "UPLOAD PHOTO";

  constructor(
    public dialogRef: MatDialogRef<openPhotoUploadAdminAccountDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,private router: Router,
    private apiHttp:ApiHttpService, private snackBar: MatSnackBar
    ) {}
    
    getSelectedFile($event) {
      this.businessLogo = $event.target.files[0];  
      this.uploadBtn = false;
    }  
    uploadFile() {
      this.uploadBtn = true;
      const _formData = new FormData();
  
      _formData.append('token',localStorage.getItem('token'));  
      _formData.append('user_id',localStorage.getItem('user_id'));  
      _formData.append('member_id',this.member_id);  
  
      _formData.append('file', this.businessLogo, this.businessLogo.name);  
  
      this.apiHttp.uploadUserPhoto(_formData)
      .subscribe(data=> {
  
        let keys = Object.keys(data);
        if(keys.length != 0) {
  
          if(data['status'] == 100) {
  
            this.snackBar.open("Upload successfully.!",'', {
              duration: 10000,
              horizontalPosition: 'center',
              verticalPosition : 'top'
            });
  
            this.dialogRef.close();
            this.router.navigate(['/admin/supplyprofile/'+this.member_id]);
            
          } else if(data['status'] == 101) {
            this.errorMsg = data['data'];           
          }
        }
  
      });
    }

  }


// EDIT LOGIN INFO DIALOG
@Component({
  selector: 'dialog-edit-login-info',
  templateUrl: '../../_component/edit-popup/dialog-edit-login-info.html',
})
export class openEditLoginInfoAdminAccountDialog {
  postParam = {username:'',member_id:'',passwd:'' };
  errorMsg  = {length:''}; 

  constructor(
    public dialogRef: MatDialogRef<openEditLoginInfoAdminAccountDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private apiHttp:ApiHttpService,private router: Router,
    private snackBar: MatSnackBar) {

    this.postParam['username']  = data['username'];
    this.postParam['member_id'] = data['member_id'];
  }

  updateLoginInfo() {
    this.apiHttp.updateUserLogin(this.postParam)
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {

        if(data['status'] == 100) {

          this.snackBar.open("Information update successfully.!",'', {
            duration: 10000,
            horizontalPosition: 'center',
            verticalPosition : 'top'
          });
          this.dialogRef.close();
          this.router.navigate(['/admin/supplyprofile/'+this.postParam['member_id']]);
        } else if(data['status'] == 101) {
          this.errorMsg = data['data'];           
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }

    });
  }
}
