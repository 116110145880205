import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ApiHttpService } from '../../../_service/api-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
export interface DialogData {

}
@Component({
  selector: 'app-product-profile',
  templateUrl: './product-profile.component.html'
})
export class ProductProfileComponent implements OnInit {

  product_id      = '';
  productInfo     = {product_title:'',product_description:'',product_mrp:'',category:'',expiry_period:'',lastupdate:'',createdon:'',photo:''};
  productStock    = {factoryInStock:'',stockValue:'',stockList:[]};

  postParamProductProfile = {};
  getData:boolean = false;

  stocklist = [
    {addedstock:"12,00,000", currentstock:"15,00,000", mfgdate:"12 August 2017", expiry:"12 August 2019"},
    {addedstock:"14,00,000", currentstock:"12,00,500", mfgdate:"12 August 2017", expiry:"12 August 2019"},
    {addedstock:"17,00,500", currentstock:"5,35,000", mfgdate:"12 August 2017", expiry:"12 August 2019"},
    {addedstock:"95,000", currentstock:"7,03,050", mfgdate:"12 August 2017", expiry:"12 August 2019"},
    {addedstock:"95,000", currentstock:"7,03,050", mfgdate:"12 August 2017", expiry:"12 August 2019"},
    {addedstock:"95,000", currentstock:"7,03,050", mfgdate:"12 August 2017", expiry:"12 August 2019"}
  ];

  constructor(private router:Router,
    private apiHttp:ApiHttpService,
    private route: ActivatedRoute,
    public dialog: MatDialog) {

    this.product_id = this.route.snapshot.paramMap.get('product_id');

    this.getProductProfile();
    this.getProductStockDetails();
  }

  ngOnInit() {
  }

  // GET USER TYPE DETAILS
  getProductProfile(): void {

    this.postParamProductProfile['product_id'] = this.product_id;
    this.apiHttp.getProductProfile(this.postParamProductProfile)
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {

        if(data['status'] == 100) {
          this.productInfo = data['data'];
          this.getData     = true;
        } else if(data['status'] == 101) {
          this.router.navigate(['/admin/productlist']);
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }

      }

    });
  }

  // GET USER TYPE DETAILS
  getProductStockDetails(): void {

    this.postParamProductProfile['product_id'] = this.product_id;
    this.apiHttp.getStockDetails(this.postParamProductProfile)
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {

        if(data['status'] == 100) {
          this.productStock = data['data'];
          this.getData     = true;
        } else if(data['status'] == 101) {
          this.router.navigate(['/admin/supplylist/1']);
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }

    });
  }

  // update Product info
  openUpdateProductDetailsDialog(): void {
    const dialogRef = this.dialog.open(openUpdateProductDetailsDialog, {
      width: '800px',
      data:{productInfo:this.productInfo}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getProductProfile();
    });
  }

  // add Product stock
  openAddProductStockDialog(): void {
    const dialogRef = this.dialog.open(openAddProductStockDialog, {
      width: '800px',
      data:{product_id:this.productInfo['id']}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getProductProfile();
      this.getProductStockDetails();
    });
  }


  openUploadProductPhoto(): void {
    const dialogRef = this.dialog.open(openUploadProductPhoto, {
      width: '400px',
      data:{product_id:this.product_id}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getProductProfile();
    });
}

}

// OPEN UPDATE PRODUCT DETAILS DIALOG
@Component({
  selector: 'dialog-update-product-details',
  templateUrl: 'dialog-update-product-details.html',
})
export class openUpdateProductDetailsDialog {
  
  productCategoryList = [];
  productInfo         = {};
  postParamFormData   = {product_title:'',product_mrp:'',product_category_id:'',expiry_period:'',product_description:'',id:''};
  getCatListLoading   = false;

  constructor(
    public dialogRef: MatDialogRef<openUpdateProductDetailsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private apiHttp:ApiHttpService,
    private router:Router,
    private snackBar: MatSnackBar
    ) {
      this.productInfo = data['productInfo'];
      this.postParamFormData = {'id':this.productInfo['id'],'product_category_id':this.productInfo['product_category_id'],'product_mrp':this.productInfo['product_mrp'],
                                'product_title':this.productInfo['product_title'],'expiry_period':this.productInfo['expiry_period'],'product_description':this.productInfo['product_description'],
                               }; 
      this.getCategoryList();
  }

  // UPDATE PRODUCT DETAILS
  updateProductInfo(): void {

    this.apiHttp.updateProductProfile(this.postParamFormData)
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {

        if(data['status'] == 100) {
          
          this.snackBar.open("Information update successfully.!",'', {
            duration: 10000,
            horizontalPosition: 'center',
            verticalPosition : 'top'
          });

          this.dialogRef.close();
          
          let rURL = "/admin/productprofile/"+this.postParamFormData['id'];
          this.router.navigate([rURL]);

        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }

      }
    });
  }

  getCategoryList() {

    this.apiHttp.getProductCategoryList({})
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {

        if(data['status'] == 100) {
          this.productCategoryList = data['data'];
          this.getCatListLoading   = true;
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }

    });
  }
}

// OPEN ADD  PRODUCT STOCK  DIALOG
@Component({
  selector: 'dialog-add-product-stock',
  templateUrl: 'dialog-add-product-stock.html',
})
export class openAddProductStockDialog {

  product_id    = '';
  postParamData = {stock_add_qty:'',mfg_date:''};

  constructor(
    public dialogRef: MatDialogRef<openAddProductStockDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private apiHttp:ApiHttpService,
    private router:Router,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe
    ) {
      this.product_id = data['product_id'];
  }

  addFactoryStock() {

    this.postParamData['user_id']     = localStorage.getItem('user_id');
    this.postParamData['product_id']  = this.product_id;
    this.postParamData['mfg_date']    = this.datePipe.transform(this.postParamData['mfg_date'], 'yyyy-MM-dd');

    this.apiHttp.addFactoryStock(this.postParamData)
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {

        if(data['status'] == 100) {


          this.snackBar.open("Stock added.!",'', {
            duration: 10000,
            horizontalPosition: 'center',
            verticalPosition : 'top'
          });

          this.dialogRef.close();

        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }

    });
  }
}

@Component({
  selector: 'dialog-upload-product-photo',
  templateUrl: '../../edit-popup/dialog-photo-upload.html',
})
export class openUploadProductPhoto {

  uploadTitle = "UPLOAD LOGO";
  userTypeData = {"user_sales_supply":"SUPPLY","login_access":false};
  errorMsg = ''
  uploadBtn = true;
  productLogo:File;
  product_id = "";

  constructor(
    public dialogRef: MatDialogRef<openUploadProductPhoto>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,private apiHttp:ApiHttpService, private snackBar: MatSnackBar,private router: Router
    ) {

      this.product_id = data['product_id'];
    }

    getSelectedFile($event) {
      this.productLogo = $event.target.files[0];  
      this.uploadBtn = false;
    } 

    uploadFile() {
      this.uploadBtn = true;
      const _formData = new FormData();
  
      _formData.append('token',localStorage.getItem('token'));  
      _formData.append('user_id',localStorage.getItem('user_id'));  
      _formData.append('product_id',this.product_id);  
  
      _formData.append('file', this.productLogo, this.productLogo.name);  
  
      console.log("PostParam"+_formData);
      this.apiHttp.uploadProductImage(_formData)
      .subscribe(data=> {
  
        let keys = Object.keys(data);
        if(keys.length != 0) {
  
          if(data['status'] == 100) {
  
            this.snackBar.open("Upload successfully.!",'', {
              duration: 10000,
              horizontalPosition: 'center',
              verticalPosition : 'top'
            });
  
            this.dialogRef.close();
            this.router.navigate(['/admin/productprofile/'+this.product_id]);
            
          } else if(data['status'] == 101) {
            this.errorMsg = data['data'];           
          }
        }
  
      });
    }
  }