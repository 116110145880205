import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-supplysales-profile',
  templateUrl: './supplysales-profile.component.html',
  styleUrls: ['./supplysales-profile.component.css']
})
export class SupplysalesProfileComponent implements OnInit {

  headerInfo =  { business_logo:"./assets/images/logo1.png", business_name:"Sreyah Technologies, Nagpur", cityState:"Nagpur, Maharashtra", usertype:"RETAILER", status:"ACTIVE", business_contact:"0712-2774770", fullname:"Prashant Fepale", business_code:"2581"} ;

  bankDetails = { bank_name : "State Bank of India",account_name : "Savings Account",account_number:"85210321456",ifsc_code:"SBI22514004"};

  userDetails = { user_name : "Prashant Fepale", mobile:"9049494771", pan:"DCBJ2485K", aadhaar:"874831577412"};

  businessDetails = { business_name:"Sreyah Technologies", email:"support@sreyah.co.in",contact:"0712-2774770",city:"Nagpur", state:"Maharashtra",pincode:"420008",gst:"HSJSM7885465",pan:"BGDH4578K", address:"308, Tulsikrupa, Garoba Maidan, Kapse Chowk, Nagpur"};
  constructor() { }

  ngOnInit() {
  }

}
