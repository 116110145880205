import { Component, OnInit } from '@angular/core';
import { ApiHttpService } from 'src/app/_service/api-http.service';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
})
export class OrderListComponent implements OnInit {
  orderList        = [];
  postParamData    = {};
  getData = false;
  
  constructor( private apiHttp:ApiHttpService) {
    this.getOrderList();
   }

  ngOnInit() {
  }

  getOrderList(){

  }
  searchList(e:any){

    let searchString = e.target.value;
    if(searchString.length > 2)
    {
      this.postParamData['searchString'] = searchString;
      console.log(this.postParamData);
      this.apiHttp.getOrderList(this.postParamData)
      .subscribe(data=> {
  
        let keys = Object.keys(data);
        if(keys.length != 0) {
  
          if(data['status'] == 100) {
            this.orderList = data['data']; 
            this.getData   = true;
          } else if(data['status'] == 101) {
            console.log("Error in order list");
          } else if(data['status'] == 103) {
            this.apiHttp.logoutUser();
          }
        }
  
      });
    }
  }

   
}
