import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { ApiHttpService } from '../../../_service/api-http.service';

export interface DialogData {
 
}

@Component({
  selector: 'app-usertypemanagement',
  templateUrl: './usertypemanagement.component.html'
})
export class UsertypemanagementComponent implements OnInit {

  postParam = {};
  salesUserType   = [];
  supplyUserType  = [];

  getDataLeft:boolean = false;

  constructor(public dialog: MatDialog,private apiHttp:ApiHttpService) { 
    this.getSupplyChainList();
  }

  ngOnInit() {
  }

  getSupplyChainList() {
    this.apiHttp.getSalesSupplyChainType(this.postParam)
    .subscribe(data=> {
      let keys = Object.keys(data);
      if(keys.length != 0) {

        if(data['status'] == 100) {

          this.salesUserType  = data['data']['salesList'];
          this.supplyUserType = data['data']['supplyList'];
          this.getDataLeft    = true;
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }

      }
    });
  }

  openSupplyDialog(): void {
    const dialogRef = this.dialog.open(DialogAddSupplyChain, {
      width: '450px',
    });
    }

    openUserDialog(): void {
      const dialogRef = this.dialog.open(DialogAddUserChain, {
        width: '450px',
      });
  }

  openEditSupplyListDialog(id,index): void {
    const dialogRef = this.dialog.open(openEditSupplyListDialog, {
      width: '650px',
      data : {'id':id,'userTypeInfo':this.supplyUserType[index]}
    });
  }


  openEditSalesListDialog(id,index): void {
    const dialogRef = this.dialog.open(openEditSalesListDialog, {
      width: '650px',
      data : {'id':id,'userTypeSalesInfo':this.salesUserType[index]}
    });

  }
  openDeleteSupplyListDialog(id): void {
    const dialogRef = this.dialog.open(openDeleteSupplyListDialog, {
      width: '650px',
      data : {'id':id}
    });
  }


  openDeleteSalesListDialog(id): void {
    const dialogRef = this.dialog.open(openDeleteSalesListDialog, {
      width: '650px',
      data : {'id':id}
    });
  }

};

@Component({
  selector: 'dialog-add-supply-chain',
  templateUrl: 'dialog-add-supply-chain.html',
})
export class DialogAddSupplyChain {

  userTypeData = {"user_sales_supply":"SUPPLY","login_access":false,usertype_short:'',users_details:'',hierarchy_level:''};
  errorMsg = ''

  constructor(
    public dialogRef: MatDialogRef<DialogAddSupplyChain>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private apiHttp:ApiHttpService) {}

  addNewSupplyChain() {
    this.apiHttp.postUserTypeAdd(this.userTypeData)
    .subscribe(data=> {
      let keys = Object.keys(data);
      if(keys.length != 0) {

        if(data['status'] == 100) {
          this.dialogRef.close();
        } else if(data['status'] == 101) {
          this.errorMsg = data['data']
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }

      }
    });
  }
}

@Component({
  selector: 'dialog-add-user-chain',
  templateUrl: 'dialog-add-user-chain.html',
})
export class DialogAddUserChain {

  userTypeData = {"user_sales_supply":"SALES","login_access":false,usertype_short:'',users_details:'',hierarchy_level:''};
  errorMsg = ''

  constructor(
    public dialogRef: MatDialogRef<DialogAddUserChain>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private apiHttp:ApiHttpService,
    private router:Router){}

  addNewSalesChain() {
    this.apiHttp.postUserTypeAdd(this.userTypeData)
    .subscribe(data=> {
      let keys = Object.keys(data);
      if(keys.length != 0) {

        if(data['status'] == 100) {
          this.dialogRef.close();
          this.router.navigate(['/admin/settings']);
        } else if(data['status'] == 101) {
          this.errorMsg = data['data']
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }
    });
  }  

}

@Component({
  selector: 'dialog-edit-supplylist',
  templateUrl: 'dialog-edit-supplylist.html',
})
export class openEditSupplyListDialog {

  postParamFormData = {hierarchy_level:'',usertype_short:'',users_details:'',login_access:''};
  userTypeData = {"user_sales_supply":"SUPPLY","login_access":false};
  errorMsg = ''
  id = '';

  isCustomer = false;

  constructor(
    public dialogRef: MatDialogRef<openEditSupplyListDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private apiHttp:ApiHttpService,
    private snackBar: MatSnackBar
    ){
     this.id = data['id'];
     this.postParamFormData = data['userTypeInfo'];
     
     if(this.postParamFormData['usertype_short'] == "CUSTOMER")
     this.isCustomer = true;
     
    }
    updateUserType() {

      this.apiHttp.updateUserType(this.postParamFormData)
      .subscribe(data=> {
  
        let keys = Object.keys(data);
        if(keys.length != 0) {
  
          if(data['status'] == 100) {
  
            this.snackBar.open("Information update successfully.!",'', {
              duration: 10000,
              horizontalPosition: 'center',
              verticalPosition : 'top'
            });
            this.dialogRef.close();
            
          } else if(data['status'] == 101) {
            this.errorMsg = data['data'];           
          } else if(data['status'] == 103) {
            this.apiHttp.logoutUser();
          }
        }
  
      });
    }
    }


@Component({
  selector: 'dialog-edit-saleslist',
  templateUrl: 'dialog-edit-saleslist.html',
})
export class openEditSalesListDialog {


  postParamFormData = {hierarchy_level:'',usertype_short:'',users_details:'',login_access:''};
  userTypeData = {"user_sales_supply":"SALES","login_access":false};
  errorMsg = ''
  id = '';
  
  constructor(
    public dialogRef: MatDialogRef<openEditSalesListDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private apiHttp:ApiHttpService,
    private snackBar: MatSnackBar
    ){
     this.id = data['id'];
     this.postParamFormData = data['userTypeSalesInfo'];
    }
    updateUserType() {

      this.apiHttp.updateUserType(this.postParamFormData)
      .subscribe(data=> {
  
        let keys = Object.keys(data);
        if(keys.length != 0) {
  
          if(data['status'] == 100) {
  
            this.snackBar.open("Information update successfully.!",'', {
              duration: 10000,
              horizontalPosition: 'center',
              verticalPosition : 'top'
            });
            this.dialogRef.close();
            
          } else if(data['status'] == 101) {
            this.errorMsg = data['data'];           
          } else if(data['status'] == 103) {
            this.apiHttp.logoutUser();
          }
        }
  
      });
    }
}

@Component({
  selector: 'dialog-delete-supplylist',
  templateUrl: '../../../_component/edit-popup/dialog-delete.html',
})
export class openDeleteSupplyListDialog {

  userTypeData = {"user_sales_supply":"SALES","login_access":false};
  errorMsg = ''
  id = '';
  constructor(
    public dialogRef: MatDialogRef<openDeleteSupplyListDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,private apiHttp:ApiHttpService,public snackBar: MatSnackBar
    ){
      this.id = data['id'];
    }
    
    deleteUserType() {

      this.apiHttp.deleteUserType({"id":this.id})
      .subscribe(data=> {
  
        let keys = Object.keys(data);
        if(keys.length != 0) {
  
          if(data['status'] == 100) {
  
            this.snackBar.open("User Deleted successfully.!",'', {
              duration: 10000,
              horizontalPosition: 'center',
              verticalPosition : 'top'
            });
            this.dialogRef.close();
            
          } else if(data['status'] == 101) {
            this.errorMsg = data['data'];           
          } else if(data['status'] == 103) {
            this.apiHttp.logoutUser();
          }
        }
  
      });
    }

    closeDialogBox() {
      this.dialogRef.close();
    }
  }


@Component({
  selector: 'dialog-delete-saleslist',
  templateUrl: '../../../_component/edit-popup/dialog-delete.html',
})
export class openDeleteSalesListDialog {

  userTypeData = {"user_sales_supply":"SALES","login_access":false};
  errorMsg = ''
  id = '';
  constructor(
    public dialogRef: MatDialogRef<openDeleteSalesListDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,private apiHttp:ApiHttpService,public snackBar: MatSnackBar
    ){
      this.id = data['id'];
    }

     deleteUserType() {

      this.apiHttp.deleteUserType({"id":this.id})
      .subscribe(data=> {
  
        let keys = Object.keys(data);
        if(keys.length != 0) {
  
          if(data['status'] == 100) {
  
            this.snackBar.open("User Deleted successfully.!",'', {
              duration: 10000,
              horizontalPosition: 'center',
              verticalPosition : 'top'
            });
            this.dialogRef.close();
            
          } else if(data['status'] == 101) {
            this.errorMsg = data['data'];           
          } else if(data['status'] == 103) {
            this.apiHttp.logoutUser();
          }
        }
  
      });
    }

    closeDialogBox() {
      this.dialogRef.close();
    }
  }