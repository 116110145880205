import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { ApiHttpService } from './api-http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private api: ApiHttpService, private router: Router) {
  }
  
  canActivate(route: ActivatedRouteSnapshot):boolean {

    const expectedRole = route.data.expectedRole;
    if(this.api.loggedIn()) {
      return true;
    } else {
      this.router.navigate([''])
      return false
    }
  }
}
