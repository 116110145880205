import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiHttpService } from '../../../_service/api-http.service';

@Component({
  selector: 'app-supply-list',
  templateUrl: './supply-list.component.html'
})
export class SupplyListComponent implements OnInit {
  
  supplyUserType    = [];
  userTypeDetails   = {};
  supplylist        = {};
  loggedInUsertype = '';
  usertype_id = '';
  
  getData:boolean       = false;
  getDataLeft:boolean   = false;

  postParamUserTypeList     = {"usertype":"SUPPLY"};
  postParamUserTypeDetails  = {};
  postParamSupplyList       = {};

  constructor(private router: Router,
              private apiHttp:ApiHttpService,
              private route: ActivatedRoute) { 
              
    this.loggedInUsertype = localStorage.getItem('sales_supply');

    this.usertype_id = this.route.snapshot.paramMap.get('usertype_id');
    if(this.usertype_id == null) {
      this.usertype_id = "1";
    } 

    this.getListOfSupplyComponent();
  }

  ngOnInit() {
  }
  
  goToAddSupplyUser(id) {
    let rUrl = "admin/addsupplyform/"+id;
    this.router.navigate([rUrl]);
  }

  gotosupplyProfile(member_id) {
    let rURL;
    if(this.loggedInUsertype == "ADMIN"){
      rURL = "/admin/supplyprofile/"+member_id;
    } else if(this.loggedInUsertype == "SUPPLY") {
      rURL = "/supply/supplyprofile/"+member_id;
    } else{
      rURL = "/sales/supplyprofile/"+member_id;
    }

    this.router.navigateByUrl(rURL);
  }

  goToUsertypeCategoty(id) {
    
    this.usertype_id = id;
    this.getData = false;

    this.getUserTypeDetails();
    this.getSupplyList();

    let rUrl = "admin/supplylist/"+id;
    this.router.navigate([rUrl]);
  }

  getSupplyList(): void {
    this.postParamSupplyList['usertype_id'] = this.usertype_id;
    this.apiHttp.getSupplyList(this.postParamSupplyList)
      .subscribe(data=> {

        let keys = Object.keys(data);
        if(keys.length != 0) {
          if(data['status'] == 100) {
            this.supplylist = data['data']['userlist'];
            this.getDataLeft = true;
            console.log(data);
          } else if(data['status'] == 103) {
            this.apiHttp.logoutUser();
          }
        }

    });
  }

  getUserTypeDetails(): void {
    
    this.postParamUserTypeDetails['usertype_id'] = this.usertype_id;
    this.apiHttp.getSalesSupplyChainType(this.postParamUserTypeDetails)
    .subscribe(data=> {
      let keys = Object.keys(data);
      if(keys.length != 0) {
        if(data['status'] == 100) {
          this.userTypeDetails = data['data'];
          this.getData = true;
        } else if(data['status'] == 101) {
          let rUrl = "admin/supplylist";
          this.router.navigate([rUrl]);
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }
    });
  }

  // GET LIST OF ALL USER TYPE
  getListOfSupplyComponent() {
    this.apiHttp.getSalesSupplyChainType(this.postParamUserTypeList)
    .subscribe(data=> {
      let keys = Object.keys(data);
      if(keys.length != 0) {
        if(data['status'] == 100) {
          this.supplyUserType = data['data']['supplyList'];
          this.usertype_id = this.supplyUserType[0].id; 
          
          this.getUserTypeDetails();
          this.getSupplyList();

        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }
    });
  }



  searchList(e:any){

    let searchString = e.target.value;
    if(searchString.length > 2)
    {
    this.postParamSupplyList['usertype_id'] = this.usertype_id;
    this.postParamSupplyList['searchString'] = searchString;
    this.apiHttp.getSupplyList(this.postParamSupplyList)
      .subscribe(data=> {

        let keys = Object.keys(data);
        if(keys.length != 0) {
          if(data['status'] == 100) {
            this.supplylist = data['data']['userlist'];
            console.log(data);
          } else if(data['status'] == 103) {
            this.apiHttp.logoutUser();
          }
        }
      });
    }
  }

}