import { Component , OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiHttpService } from '../../_service/api-http.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})  
export class HeaderComponent implements OnInit {

  userPhoto = '';
  loggedInUsertype = '';
  adminMenu:any;
  supplyMenu:any;
  salesMenu:any;
  selected :any;

  displaySearList  = false;
  searchResultList = [];

  constructor(private router: Router,public apiHttp: ApiHttpService) {

    this.userPhoto = localStorage.getItem('photo');
    this.loggedInUsertype = localStorage.getItem('sales_supply');

    this.adminMenu = [
                      {title:'Dashboard',link:'dashboard',submenu:['dashboard']},
                      {title:'Supply Chain',link:'supplylist'},
                      {title:'Sales Chain',link:'saleslist'},
                      {title:'Orders',link:'order'},
                      {title:'Products',link:'productlist'},
                      {title:'Settings',link:'settings'}
                    ]; 

   this.supplyMenu = [
                      {title:'Dashboard',link:'dashboard'},
                      {title:'Supply Chain',link:'supplylist'},
                      {title:'Orders',link:'order'},
                      {title:'Products',link:'productlist'}
                    ]; 
                    
    this.salesMenu = [
                      {title:'Dashboard',link:'dashboard'},
                      {title:'Team',link:'teamlist'},
                      {title:'Orders',link:'order'},
                      {title:'Supply Chain',link:'supplylist'},
                      {title:'Reports',link:'reports'},
                      {title:'Products',link:'productlist'}
                    ]; 

  }
  
  select(item) {
      this.selected = item; 
  };

  isActive(item) {
    return this.selected === item;
  };


  ngOnInit() {
  }

  topHeaderSearch(e:any) {

    this.displaySearList = true;
    let searchStr = e.target.value;

    this.searchResultList = [{'photo':"http://salessupply.sreyah.in/_backend/data/businessLogo/defaultlogo.jpeg",
                            'primaryTitle':"Prashant Fepale","secondaryTitle":"Nagpur,Maharashtra"},
                           {'photo':"http://salessupply.sreyah.in/_backend/data/businessLogo/defaultlogo.jpeg",
                            'primaryTitle':"Deepali Fepale","secondaryTitle":"Nagpur,Maharashtra"},
                          ];

  }

}
