import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgetPasswordComponent } from './login/forget-password/forget-password.component';

import { DashboardAdminComponent } from './admin/dashboard-admin/dashboard-admin.component';

import { BaseOrderListComponent } from './_component/order/base-order-list/base-order-list.component';
import { OrderListComponent } from './_component/order/order-list/order-list.component';
import { OrderViewComponent } from './_component/order/order-view/order-view.component';
import { OrderBookComponent } from './_component/order/order-book/order-book.component';

import { UsertypemanagementComponent } from './admin/settings/usertypemanagement/usertypemanagement.component';

import { SupplyListComponent } from './_component/supply-chain/supply-list/supply-list.component';
import { SupplyProfileComponent } from './_component/supply-chain/supply-profile/supply-profile.component';
import { SupplyPurchaseHistoryComponent } from './_component/supply-chain/supply-profile/supply-purchase-history/supply-purchase-history.component';
import { SupplySalesHistoryComponent } from './_component/supply-chain/supply-profile/supply-sales-history/supply-sales-history.component';
import { SupplychainformAdminComponent } from './admin/supplychainform-admin/supplychainform-admin.component';

import { UserListComponent } from './_component/user-chain/user-list/user-list.component';
import { UserProfileComponent } from './_component/user-chain/user-profile/user-profile.component';
import { UserSalesHistoryComponent } from './_component/user-chain/user-profile/user-sales-history/user-sales-history.component';
import { SalestrackingComponent } from './_component/user-chain/user-profile/salestracking/salestracking.component';
import { UserchainformAdminComponent } from './admin/userchainform-admin/userchainform-admin.component';
import { SupplyProfileLeftsideComponent } from './_component/supply-chain/supply-profile/supply-profile-leftside/supply-profile-leftside.component';

import { ProductListComponent } from './_component/product/product-list/product-list.component';
import { ProductProfileComponent } from './_component/product/product-profile/product-profile.component';
import { ProductCategoryComponent } from './_component/product/product-category/product-category.component';
import { RateCategoryComponent } from './_component/product/rate-category/rate-category.component';
import { ProductformAdminComponent } from './admin/productform-admin/productform-admin.component';
import { DeficitstockComponent } from './_component/deficitstock/deficitstock.component';

import { PagenotfoundComponent } from './_component/pagenotfound/pagenotfound.component';

import { DashboardSupplyComponent } from './supply/dashboard-supply/dashboard-supply.component';
import { SupplysalesProfileComponent } from './_component/supplysales-profile/supplysales-profile.component';

import { InvoiceComponent } from './_component/print/invoice/invoice.component';
import { AccountAdminComponent } from './admin/account-admin/account-admin.component';
import { AccountSupplyComponent } from './supply/account-supply/account-supply.component';


import { AccountSalesComponent } from './user/account-sales/account-sales.component';
import { DashboardSalesComponent } from './user/dashboard-sales/dashboard-sales.component';
import { ReportsComponent } from './user/reports/reports.component';


import { AuthGuard } from './_service/auth.guard';

const routes: Routes = [

  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'forgetpwd', component: ForgetPasswordComponent },

  { path: 'admin/order', component: OrderListComponent , canActivate: [AuthGuard],data:{expectedRole:'admin'}},
  { path: 'admin/orderview/:order_id', component: OrderViewComponent , canActivate: [AuthGuard],data:{expectedRole:'admin'}},
  { path: 'admin/orderbook', component: OrderBookComponent , canActivate: [AuthGuard],data:{expectedRole:'admin'}},
  { path: 'admin/orderbook/:id', component: OrderBookComponent , canActivate: [AuthGuard],data:{expectedRole:'admin'}},
  { path: 'admin/invoice/:order_id', component: InvoiceComponent , canActivate: [AuthGuard],data:{expectedRole:'admin'}},

  { path: 'admin/settings', component: UsertypemanagementComponent , canActivate: [AuthGuard],data:{expectedRole:'admin'}},
  { path: 'admin/dashboard', component: DashboardAdminComponent,canActivate:[AuthGuard],data:{expectedRole:'admin'}},
  { path: 'admin/account', component: AccountAdminComponent , canActivate: [AuthGuard],data:{expectedRole:'admin'}},

  { path: 'admin/supplylist', component: SupplyListComponent , canActivate: [AuthGuard],data:{expectedRole:'admin'}},
  { path: 'admin/supplylist/:usertype_id', component: SupplyListComponent , canActivate: [AuthGuard],data:{expectedRole:'admin'}},

  { path: 'admin/addsupplyform', redirectTo: "admin/supplylist", pathMatch: "full" },
  { path: 'admin/addsupplyform/:usertype_id', component: SupplychainformAdminComponent , canActivate: [AuthGuard],data:{expectedRole:'admin'}},
  { path: 'admin/supplyprofile/:member_id', component: SupplyProfileComponent , canActivate: [AuthGuard],data:{expectedRole:'admin'}},
  { path: 'admin/supplypurchasehistory/:member_id', component: SupplyPurchaseHistoryComponent , canActivate: [AuthGuard],data:{expectedRole:'admin'}},
  { path: 'admin/supplysaleshistory/:member_id', component: SupplySalesHistoryComponent , canActivate: [AuthGuard],data:{expectedRole:'admin'}},
  
  { path: 'admin/usersaleshistory', component: UserSalesHistoryComponent, canActivate: [AuthGuard],data:{expectedRole:'admin'}},
  
  { path: 'admin/productlist', component: ProductListComponent , canActivate: [AuthGuard],data:{expectedRole:'admin'}},
  { path: 'admin/productprofile/:product_id', component: ProductProfileComponent , canActivate: [AuthGuard],data:{expectedRole:'admin'}},
  { path: 'admin/productcategory', component: ProductCategoryComponent , canActivate: [AuthGuard],data:{expectedRole:'admin'}},
  { path: 'admin/addproduct', component: ProductformAdminComponent , canActivate: [AuthGuard],data:{expectedRole:'admin'}},
  { path: 'admin/ratecategory', component: RateCategoryComponent , canActivate: [AuthGuard],data:{expectedRole:'admin'}},

  { path: 'admin/saleslist', component: UserListComponent ,canActivate:[AuthGuard],data:{expectedRole:'admin'}},
  { path: 'admin/saleslist/:usertype_id', component: UserListComponent ,canActivate:[AuthGuard],data:{expectedRole:'admin'}},
  { path: 'admin/addsalesform/:usertype_id', component: UserchainformAdminComponent ,canActivate:[AuthGuard],data:{expectedRole:'admin'}},
  { path: 'admin/salesprofile/:id', component: UserProfileComponent ,canActivate:[AuthGuard],data:{expectedRole:'admin'}},
  { path: 'admin/salessaleshistory/:id', component: UserSalesHistoryComponent,canActivate:[AuthGuard],data:{expectedRole:'admin'} },
  { path: 'admin/salestracking/:id', component: SalestrackingComponent,canActivate:[AuthGuard],data:{expectedRole:'admin'} },

  { path: 'supply/dashboard', component: DashboardSupplyComponent, canActivate: [AuthGuard],data:{expectedRole:'supply'}},
  { path: 'supply/account', component: AccountSupplyComponent , canActivate: [AuthGuard],data:{expectedRole:'supply'}},

  { path: 'supply/supplylist', component: SupplyListComponent , canActivate: [AuthGuard],data:{expectedRole:'supply'}},
  { path: 'supply/supplyprofile/:member_id', component: SupplysalesProfileComponent , canActivate: [AuthGuard],data:{expectedRole:'supply'}},

  { path: 'supply/order', component: OrderListComponent , canActivate: [AuthGuard],data:{expectedRole:'supply'}},
  { path: 'supply/orderview/:order_id', component: OrderViewComponent , canActivate: [AuthGuard],data:{expectedRole:'supply'}},
  { path: 'supply/orderbook', component: OrderBookComponent , canActivate: [AuthGuard],data:{expectedRole:'supply'}},

  { path: 'supply/productlist', component: ProductListComponent , canActivate: [AuthGuard],data:{expectedRole:'supply'}},
  { path: 'supply/productprofile/:product_id', component: ProductProfileComponent , canActivate: [AuthGuard],data:{expectedRole:'supply'}},
  { path: 'supply/deficitstock', component: DeficitstockComponent , canActivate: [AuthGuard],data:{expectedRole:'supply'}},
  { path: 'supply/invoice', component: InvoiceComponent , canActivate: [AuthGuard],data:{expectedRole:'supply'}},

  { path: 'sales/supplylist', component: SupplyListComponent , canActivate: [AuthGuard],data:{expectedRole:'sales'}},
  { path: 'sales/supplyprofile/:member_id', component: SupplysalesProfileComponent , canActivate: [AuthGuard],data:{expectedRole:'sales'}},
  
  { path: 'sales/dashboard', component: DashboardSalesComponent, canActivate: [AuthGuard],data:{expectedRole:'sales'}},
  { path: 'sales/order', component: OrderListComponent , canActivate: [AuthGuard],data:{expectedRole:'sales'}},
  { path: 'sales/orderview/:order_id', component: OrderViewComponent , canActivate: [AuthGuard],data:{expectedRole:'sales'}},
  { path: 'sales/orderbook', component: OrderBookComponent , canActivate: [AuthGuard],data:{expectedRole:'sales'}},
  { path: 'sales/productlist', component: ProductListComponent , canActivate: [AuthGuard],data:{expectedRole:'sales'}},
  { path: 'sales/productprofile/:product_id', component: ProductProfileComponent , canActivate: [AuthGuard],data:{expectedRole:'sales'}},
  { path: 'sales/account', component: AccountSalesComponent , canActivate: [AuthGuard],data:{expectedRole:'sales'}},
  { path: 'sales/reports', component: ReportsComponent , canActivate: [AuthGuard],data:{expectedRole:'sales'}},
  { path: 'sales/teamlist', component: UserListComponent ,canActivate:[AuthGuard],data:{expectedRole:'sales'}},
  { path: 'sales/salesprofile/:id', component: UserProfileComponent ,canActivate:[AuthGuard],data:{expectedRole:'sales'}},
  
  { path: '**', component: PagenotfoundComponent},
];

@NgModule({
  imports: [ RouterModule.forRoot(routes,{useHash: true})],
  exports: [RouterModule]
})

export class AppRoutingModule {}
export const routingComponents = [LoginComponent, DashboardAdminComponent, ForgetPasswordComponent, OrderListComponent,
                                  UsertypemanagementComponent, SupplyListComponent, UserListComponent, ProductListComponent,
                                  SupplyProfileComponent, UserProfileComponent, ProductProfileComponent, SupplyPurchaseHistoryComponent,
                                  SupplySalesHistoryComponent, BaseOrderListComponent, UserSalesHistoryComponent, ProductCategoryComponent,
                                  SupplychainformAdminComponent, UserchainformAdminComponent, PagenotfoundComponent, OrderViewComponent,
                                  OrderBookComponent, ProductformAdminComponent, DashboardSupplyComponent, SupplysalesProfileComponent,
                                  DeficitstockComponent,InvoiceComponent, SalestrackingComponent,RateCategoryComponent,SupplyProfileLeftsideComponent,
                                  AccountAdminComponent,AccountSupplyComponent,AccountSalesComponent,DashboardSalesComponent,ReportsComponent
                                  ];
