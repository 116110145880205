import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs'
import { Router } from '@angular/router';
import { Constants } from "./constants";

@Injectable({
  providedIn: 'root'
})
export class ApiHttpService {

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };

  constructor(private http: HttpClient,
              private router:Router,
              private apilist:Constants) {
    console.log(localStorage.getItem('token'));
  }

  // LOGIN CHECK SERVICE
  postLoginCheck(postParam):Observable<{}>{
    postParam["api_key"] = this.apilist.api_key;
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.login,postParam,this.httpOptions);
  }

  // GET ADMIN DASHBOARD
  getDashboardAdmin(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.adminDashboard,postParam,this.httpOptions)
  }

  // USER TYPE MANAGMENT SERVICE
  // 1. Add Usertype to database
  postUserTypeAdd(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.userTypeAdd,postParam,this.httpOptions)
  }

  // 2. Get Sales and Supply Type List
  getSalesSupplyChainType(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.getSalesSupplyChain,postParam,this.httpOptions)
  }

  // 3. Update Sales and Supply Type List
  updateUserType(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.updateUserType,postParam,this.httpOptions)
  }
// 4. Delete Sales and Supply Type List
deleteUserType(postParam):Observable<{}> {
  postParam["token"]   = localStorage.getItem('token');
  postParam["user_id"] = localStorage.getItem('user_id');
  postParam = JSON.stringify(postParam);
  return this.http.post<{}>(this.apilist.deleteUserType,postParam,this.httpOptions)
}


  // SUPPLY LIST MANAGMENT SERVICE
  // 1. get Supply user list
  getSupplyList(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.getSupplyList,postParam,this.httpOptions)
  }

  // 2. add supply user to serve
  addSupplyUser(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.addSupplyUser,postParam,this.httpOptions)
  }

  // 3. get Supply user profile
  getSupplyProfile(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.profileSupplyUser,postParam,this.httpOptions)
  }

  // 4. Update supply business info
  updateSupplyBusinessInfo(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.updateSupplyBusinessInfo,postParam,this.httpOptions)
  }

  // 5. Update supply Owner Info info
  updateSupplyOwnerInfo(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.updateSupplyOwnerInfo,postParam,this.httpOptions)
  }

  // 6. get service provider list by hirarchy level
  getServiceProviderList(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.getServiceProviderList,postParam,this.httpOptions)
  }

  // 7. update service provider
  updateServiceProvider(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    console.log(postParam);
    console.log(this.apilist.updateServiceProvider);
    return this.http.post<{}>(this.apilist.updateServiceProvider,postParam,this.httpOptions)
  }
  // COMMON SERVICE USE
  // 1. Update login info
  updateUserLogin(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.updateUserLogin,postParam,this.httpOptions)
  }

  // 2. Update login info
  updateBankDetails(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.updateBankDetails,postParam,this.httpOptions)
  }

  // SUPPLY LIST MANAGMENT SERVICE
  // 1. get sales user list
  getSalesList(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.getSalesUserList,postParam,this.httpOptions)
  }

  // 2. add sales user to server
  addSalesUser(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.addSalesUser,postParam,this.httpOptions)
  }

  // 3. get sales user profile
  profileHeaderSalesUser(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.profileHeaderSalesUser,postParam,this.httpOptions)
  }

  // 3. get sales user profile
  profileSalesUser(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.profileSalesUser,postParam,this.httpOptions)
  }

  // 3. get sales user profile
  updateSalesUserBasicInfo(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.updateSalesBasicInfo,postParam,this.httpOptions)
  }
   // 4. get sales location profile
   getLocationList(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.getLocationList,postParam,this.httpOptions)
  }

    // 4. get sales profile timeline
    salesProfileActivityLog(postParam):Observable<{}> {
      postParam["token"]   = localStorage.getItem('token');
      postParam["user_id"] = localStorage.getItem('user_id');
      postParam = JSON.stringify(postParam);
      return this.http.post<{}>(this.apilist.salesProfileActivityLog,postParam,this.httpOptions)
    }

    // 5. get sales profile yearly sales history
    getyearlySalesHistory(postParam):Observable<{}> {
      postParam["token"]   = localStorage.getItem('token');
      postParam["user_id"] = localStorage.getItem('user_id');
      postParam = JSON.stringify(postParam);
      return this.http.post<{}>(this.apilist.getyearlySalesHistory,postParam,this.httpOptions)
    }
  // PRODUCT MANAGEMENT
  // 1. add Product Category
  addProductCategory(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.addProductCategory,postParam,this.httpOptions)
  }

  // 2. Get product category list
  getProductCategoryList(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.getProductCategoryList,postParam,this.httpOptions)
  }

  // 3. Update product category
  updateProductCategory(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.updateProductCategory,postParam,this.httpOptions)
  }

  // 4. add Product
  addProduct(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.addProduct,postParam,this.httpOptions)
  }

  // 5. Product List
  getProductList(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.getProductList,postParam,this.httpOptions)
  }

  // 6. Product Profile
  getProductProfile(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.getProductProfile,postParam,this.httpOptions)
  }

  // 7. Update Product Profile
  updateProductProfile(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.updateProductInfo,postParam,this.httpOptions)
  }

  // 8. Add Rate category
  addRateCategory(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.addRateCategory,postParam,this.httpOptions)
  }

  // 9. Get Rate category list
  getRateCategoryList(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.getRateCategoryList,postParam,this.httpOptions)
  }

  // 10. Get product list by category id
  getRateListByCategory(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.getRateListByCategory,postParam,this.httpOptions)
  }

  // 11. Update single rate list item
  updateRateListSingle(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.updateRateListSingle,postParam,this.httpOptions)
  }

  // STOCK UPDATE SERVICE
  // 1. Add factory stock
  addFactoryStock(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.addFactoryStock,postParam,this.httpOptions)
  }

  // 2. Get Stock Details
  getStockDetails(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.getStockDetails,postParam,this.httpOptions)
  }

  // UPLOAD
  // 1. User profile/business logo upload
  uploadUserPhoto(postParam):Observable<{}> {
    return this.http.post<{}>(this.apilist.uploadUserPhoto,postParam)
  }

  // UPLOAD
  // 2. Product profile/product logo upload
  uploadProductImage(postParam):Observable<{}> {
    return this.http.post<{}>(this.apilist.uploadProductImage,postParam)
  }

  // 3. upload price list excel
  uploadExcelPriceList(postParam):Observable<{}> {
    return this.http.post<{}>(this.apilist.uploadExcelPriceList,postParam)
  }

  // ORDER HANDLING
  // 1. get product list with no limit
  getProductListNoLimit(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.getProductListNoLimit,postParam,this.httpOptions)
  }

  // 2. get order by and order to details
  getOrderByOrderTo(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.getOrderByOrderTo,postParam,this.httpOptions)
  }

  // 3. get single product details
  getSingleProductInfoOrder(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.getSingleProductInfoOrder,postParam,this.httpOptions)
  }

  // 4. final checkout
  placeOrder(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.placeOrder,postParam,this.httpOptions)
  }

  // 5. get order list
  getOrderList(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.getOrderList,postParam,this.httpOptions)
  }

  // get order view
  singleOrderView(postParam):Observable<{}> {
    postParam["token"]   = localStorage.getItem('token');
    postParam["user_id"] = localStorage.getItem('user_id');
    postParam = JSON.stringify(postParam);
    return this.http.post<{}>(this.apilist.singleOrderView, postParam, this.httpOptions)
  }

  loggedIn() {
    let token = localStorage.getItem('token');
    if(token == null) {
      return false
    } else {
      return true
    }
  }

  logoutUser() {
    localStorage.clear();
    this.router.navigate(['']);
  }
}
