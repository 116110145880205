import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ApiHttpService } from '../../../_service/api-http.service';
import { Router, ActivatedRoute } from '@angular/router';

export interface DialogData {
}

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['user-profile.component.css']
})

export class UserProfileComponent implements OnInit {

// TEAM ASSIGNMENT
teamlist = [
    {level:"#1", photo:"../assets/images/girl.png",  designation:"Regional Sales Manager", name:"Anjali Bodkhe",  city:"Nagpur",  state:"Maharashtra"},
    {level:"#2", photo:"../assets/images/girl1.png",  designation:"Area Sales Manager", name:"Shweta Shambhargade", city:"Amravati",  state:"Maharashtra"},
    {level:"#3", photo:"../assets/images/boy.png",  designation:"Sales Officer", name:"Prashant Fepale", city:"Badnera",state:"Maharashtra"},
    {level:"#4", photo:"../assets/images/girl.png",  designation:"Sales Representative", name:"Neha Patil",  city:"Badnera",  state:"Maharashtra"}
  ];



   // Header Graph

   public barChartOptions:any = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public chartColors: any[]     = [{ backgroundColor:["#f88a81", "#ffe8a2", "#f88a81", "#ffe8a2", "#f88a81", '#ffe8a2', "#f88a81"] }];
  public barChartType:string    = 'bar';
  public barChartLegend:boolean = true;

  public barChartLabels:string[] = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
  public barChartData:any[] = [
    {data: [65, 49, 100, 81, 36, 55, 50], label: 'Weekly Sales'}
  ];


 // events
 public chartClicked(e:any):void {
  console.log(e);
}

public chartHovered(e:any):void {
  console.log(e);
}

  headerInfo           = {};
  basicInfo         = {};
  bankDetails       = {};
  lastupdateDetails = {};
  timeLine          = [];
  postParamUserProfile = {};
  hierarchy_level: '';


  member_id       = '';
  getData:boolean = false;

  constructor(private router: Router,
              private apiHttp:ApiHttpService,
              private route: ActivatedRoute,
              public dialog: MatDialog) {

              this.member_id = this.route.snapshot.paramMap.get('id');
              this.getUserProfile();
              this.getUserProfileHeader();
              this.salesProfileActivityLog();
        }

  ngOnInit() {}


  // GET USER TYPE DETAILS
  getUserProfileHeader(): void {

    this.postParamUserProfile['member_id'] = this.member_id;
    this.apiHttp.profileHeaderSalesUser(this.postParamUserProfile)
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {

        if(data['status'] == 100) {

          this.headerInfo = data['data']['headerInfo'];
          this.getData    = true;

        } else if(data['status'] == 101) {

          this.router.navigate(['/admin/saleslist/1']);
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }
    });
  }

  // GET USER TYPE DETAILS
  getUserProfile(): void {

    this.postParamUserProfile['member_id'] = this.member_id;
    this.apiHttp.profileSalesUser(this.postParamUserProfile)
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {

        if(data['status'] == 100) {

          this.basicInfo          = data['data']['basicInfo'];
          this.bankDetails        = data['data']['bankDetails'];
          this.lastupdateDetails  = data['data']['lastupdateDetails'];

          this.getData      = true;

        } else if(data['status'] == 101) {
          this.router.navigate(['/admin/saleslist/1']);
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }

    });
  }

  // Timeline
  salesProfileActivityLog(): void {

    this.postParamUserProfile['member_id'] = this.member_id;
    this.apiHttp.salesProfileActivityLog(this.postParamUserProfile)
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {

        if(data['status'] == 100) {
          this.timeLine = data['data'];
        } else if(data['status'] == 101) {
          this.router.navigate(['/admin/saleslist/1']);
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }
    });
  }

  // UPDATE BASIC INFO
  openEditBasicInfoDialog(): void {
    const dialogRef = this.dialog.open(DialogEditBasicInfo, {
      width: '800px',
      data:{basicInfo:this.basicInfo,member_id:this.member_id}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getUserProfile();
    });
  }

  // UPDATE BANK DETAILS
  openEditBankDetailsDialog(): void {
    const dialogRef = this.dialog.open(openEditBankDetailsDialog, {
      width: '800px',
      data:{bankDetails:this.bankDetails,member_id:this.member_id}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getUserProfile();
    });
  }

  // UPDATE LOGIN INFO
  openEditLoginInfoDialog(): void {
    const dialogRef = this.dialog.open(openEditLoginInfoDialog, {
      width: '800px',
      data:{username:this.basicInfo['username'],member_id:this.member_id}
    });

    dialogRef.afterClosed().subscribe(result => {
       this.getUserProfile();
    });
  }

  // SEND TEXT MESSAGE
  openSendMessageDialog(): void {
    const dialogRef = this.dialog.open(openSendMessageDialog, {
      width: '600px',
    });
  }

  // DELETE USER
  openDeleteDialog(): void {
    const dialogRef = this.dialog.open(openDeleteDialog, {
      width: '600px',
    });
  }

  // UPDATE TEAM (ASSIGNMENT) MEMBER
  openUpdateTeamMemberDialog(): void {
    const dialogRef = this.dialog.open(openUpdateTeamMemberDialog, {
      width: '400px',
      data:{hierarchy_level:this.hierarchy_level}
    });
  }
}

// EDIT BASIC INFO DIALOG
@Component({
  selector: 'dialog-edit-basic-info',
  templateUrl: 'dialog-edit-basic-info.html',
})
export class DialogEditBasicInfo {

  postParamFormData = {fullname:'',mobile1:'',mobile2:'',email:'',dob:'',pincode:'',adhaar_no:'',pan_no:'',address:''};
  member_id = '';
  errorMsg  = [];

  constructor(
    public dialogRef: MatDialogRef<DialogEditBasicInfo>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private apiHttp:ApiHttpService,private router: Router,
    private snackBar: MatSnackBar
    ) {
      this.postParamFormData = data['basicInfo'];
      this.member_id         = data['member_id'];
    }

    updateBasicInfo() {

      this.postParamFormData['id'] = this.member_id;
      this.apiHttp.updateSalesUserBasicInfo(this.postParamFormData)
      .subscribe(data=> {

        let keys = Object.keys(data);
        if(keys.length != 0) {

          if(data['status'] == 100) {

            this.snackBar.open("Information update successfully.!",'', {
              duration: 10000,
              horizontalPosition: 'center',
              verticalPosition : 'top'
            });

            this.dialogRef.close();
            this.router.navigate(['/admin/salesprofile/'+this.member_id]);

          } else if(data['status'] == 101) {
            this.errorMsg = data['data'];
          } else if(data['status'] == 103) {
            this.apiHttp.logoutUser();
          }
        }

      });
    }
  }

// EDIT BANK DETAILS DIALOG
@Component({
  selector: 'dialog-edit-bank-details',
  templateUrl: '../../edit-popup/dialog-edit-bank-details.html',
})
export class openEditBankDetailsDialog {

  postParam = {bank_name:'',account_name:'',account_number:'',ifsc_code:''};
  member_id = '';
  errorMsg  = [];

  constructor(
    public dialogRef: MatDialogRef<openEditBankDetailsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private apiHttp:ApiHttpService,private router: Router,
    private snackBar: MatSnackBar) {

      this.postParam = data['bankDetails'];
      this.member_id = data['member_id'];
    }

    updateBankDetailsInfo() {

      this.postParam['id'] = this.member_id;
      this.apiHttp.updateBankDetails(this.postParam)
      .subscribe(data=> {

        let keys = Object.keys(data);
        if(keys.length != 0) {

          if(data['status'] == 100) {

            this.snackBar.open("Information update successfully.!",'', {
              duration: 10000,
              horizontalPosition: 'center',
              verticalPosition : 'top'
            });

            this.dialogRef.close();
            this.router.navigate(['/admin/salesprofile/'+this.member_id]);
          } else if(data['status'] == 101) {
            this.errorMsg = data['data'];
          } else if(data['status'] == 103) {
            this.apiHttp.logoutUser();
          }
        }

      });
    }

  }

// EDIT LOGIN INFO DIALOG
@Component({
  selector: 'dialog-edit-login-info',
  templateUrl: '../../edit-popup/dialog-edit-login-info.html',
})
export class openEditLoginInfoDialog {

  postParam = {username:'',member_id:'',passwd:''};
  errorMsg  = {length:''};

  constructor(
    public dialogRef: MatDialogRef<openEditLoginInfoDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private apiHttp:ApiHttpService,private router: Router,
    private snackBar: MatSnackBar
    ) {
      this.postParam['username']  = data['username'];
      this.postParam['member_id'] = data['member_id'];
    }

    updateLoginInfo() {
      this.apiHttp.updateUserLogin(this.postParam)
      .subscribe(data=> {

        let keys = Object.keys(data);
        if(keys.length != 0) {

          if(data['status'] == 100) {

            this.snackBar.open("Information update successfully.!",'', {
              duration: 10000,
              horizontalPosition: 'center',
              verticalPosition : 'top'
            });

            this.dialogRef.close();
            this.router.navigate(['/admin/salesprofile/'+this.postParam['member_id']]);

          } else if(data['status'] == 101) {
            this.errorMsg = data['data'];
          } else if(data['status'] == 103) {
            this.apiHttp.logoutUser();
          }
        }

      });
    }
  }

// SEND MESSAGE DIALOG
@Component({
  selector: 'dialog-send-message',
  templateUrl: '../../edit-popup/dialog-send-message.html',
})
export class openSendMessageDialog {

  userTypeData = {"user_sales_supply":"SUPPLY","login_access":false};
  errorMsg = ''

  constructor(
    public dialogRef: MatDialogRef<openSendMessageDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}
  }

// DELETE USER DIALOG
@Component({
  selector: 'dialog-delete',
  templateUrl: '../../edit-popup/dialog-delete.html',
})
export class openDeleteDialog {

  userTypeData = {"user_sales_supply":"SUPPLY","login_access":false};
  errorMsg = ''

  constructor(
    public dialogRef: MatDialogRef<openDeleteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    deleteUserType(){}
    
    closeDialogBox() {
      this.dialogRef.close();
    }
  }


// UPDATE TEAM(ASSIGNMENT) MEMBER
@Component({
  selector: 'dialog-update-team-member',
  templateUrl: '../../edit-popup/dialog-update-team-member.html',
})
export class openUpdateTeamMemberDialog {

  updateTitle = "UPDATE MANAGER";
  placeholder = "Select Manager";

  postParamServiceProvider = {'service_provider':'1'};
  hierarchy_level = '';
  serviceProviderList = [];
  getData:boolean = false;
  postParamServiceProviderList = {};

  constructor(
    public dialogRef: MatDialogRef<openUpdateTeamMemberDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private apiHttp:ApiHttpService,
    ) {
      this.hierarchy_level = data['hierarchy_level'];
      this.getServiceProviderList();
    }
      // GET USER TYPE DETAILS
      getServiceProviderList(): void {

        this.postParamServiceProviderList['hierarchy_level'] = this.hierarchy_level;
        this.apiHttp.getServiceProviderList(this.postParamServiceProviderList)
        .subscribe(data=> {

          let keys = Object.keys(data);
          if(keys.length != 0) {
            if(data['status'] == 100) {
              this.serviceProviderList = data['data'];
              this.getData = true;
            } else if(data['status'] == 103) {
              this.apiHttp.logoutUser();
            }
          }

        });
      }

      updateServiceProvider(){
        console.log("Updated" +JSON.stringify(this.postParamServiceProvider));

      }
  }

