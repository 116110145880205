import { Component, OnInit } from '@angular/core';
import { ApiHttpService } from '../../_service/api-http.service';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-dashboard-admin',
  templateUrl: './dashboard-admin.component.html'
})
export class DashboardAdminComponent implements OnInit {
  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true
  };

  public barChartType:string= 'bar';
  public barChartLegend:boolean = true;
  public chartColors: any[] = [
    { 
    backgroundColor:["#f88a81", "#ffe8a2", "#f88a81", "#ffe8a2", "#f88a81",
                     '#ffe8a2', "#f88a81", "#ffe8a2", "#f88a81", "#ffe8a2",
                     "#f88a81", '#ffe8a2']
    }
];

  public getData: boolean = false;
  public dashboardHeader: any;
  public salesFigureData: any;
  public orderList: any;
  public topSalesMember: any;
  public userActivity: any;

  public barChartLabels:string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  
  public barChartData:any[] = [{
    data: [65, 49, 100, 81, 36, 55, 50, 37, 85, 64, 42, 60], 
    label: 'Yearly Sales'
  }];
  
 // events
 public chartClicked(e:any):void {
  console.log(e);
}

public chartHovered(e:any):void {
  console.log(e);
}
  constructor(private apiHttp:ApiHttpService,public snackBar: MatSnackBar) {
    this.getDashboardData();
  }

  ngOnInit() {
  }

  postParam = {};
  getDashboardData() {
    
    this.apiHttp.getDashboardAdmin(this.postParam)
      .subscribe(data=> {

        let keys = Object.keys(data);
        if(keys.length != 0) {
          if(data['status'] == 100) { 

            // DASHBOARD HEADER
            let userInfo = data['data']['userInfo'];
            this.dashboardHeader = {loginuser:userInfo.fullname,thought:userInfo.thought,lastlogin:userInfo.lastlogin};

            this.salesFigureData = data['data']['salesList']             // SALES FIGURE
            this.orderList       = data['data']['orderList']            // ORDER LIST
            this.topSalesMember  = data['data']['topsalesMember']      // top Sales Member
            this.userActivity    = data['data']['userActivity']       // top Sales Member

            this.getData = true;
            
          } else {
            this.apiHttp.logoutUser();
          }
        }
    })    
  }

}
