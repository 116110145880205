import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ApiHttpService } from 'src/app/_service/api-http.service';
import { Router, ActivatedRoute } from '@angular/router';
export interface DialogData {

}

@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.css']
})
export class OrderViewComponent implements OnInit {

  order_id  = "";
  orderTimeline = [ {"atime" : "10:00am", "activity" : "Logged In"},
                    {"atime" : "10:00am", "activity" : "Logged In"}
                  ];

  order_details     = { order_id : '', book_date : '', lastupdate : '',  order_status : '', order_amount : 0.0, order_tax : 0.0};
  // tslint:disable-next-line:max-line-length
  order_by          = { id: '', business_name: '', business_contact: '', business_address : '', business_pincode : '', business_gst_no : '', cityState: ''};
  // tslint:disable-next-line:max-line-length
  order_to          = { id: '', business_name: '', business_contact: '', business_address: '', business_pincode: '', business_gst_no: '', cityState: ''};
  order_book_by     = { id: '',fullname: '',usertype: ''};
  orderSummary      = [];

  constructor(private router: Router,public dialog: MatDialog,private route: ActivatedRoute,
    private apiHttp:ApiHttpService,private snackBar: MatSnackBar) {

    this.order_id = this.route.snapshot.paramMap.get('order_id');
    this.singleOrderView();
  }

  ngOnInit() {
  }

  singleOrderView() {

    let postParamData = {};
    postParamData['order_id'] = this.order_id;

    this.apiHttp.singleOrderView(postParamData)
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {

        if(data['status'] == 100) {

          this.order_details     = data['data']['order_details'];
          this.order_by          = data['data']['order_by'];
          this.order_to          = data['data']['order_to'];
          this.order_book_by     = data['data']['order_book_by'];
          this.orderSummary      = data['data']['order_summary'];

        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }

    });
  }


  openEditProductDialog(): void {
    const dialogRef = this.dialog.open(openEditProductDialog, {
      width: '1000px',
    });
  }

  openOrderTimeline(): void {
    const dialogRef = this.dialog.open(openOrderTimeline, {
      width: '500px',
      data : { orderTimeline : this.orderTimeline}
    });
  }
}


// EDIT LOGIN INFO DIALOG
@Component({
  selector: 'dialog-edit-product',
  templateUrl: 'dialog-edit-product.html',
})
export class openEditProductDialog {
  userTypeData = { "user_sales_supply" : "SUPPLY", "login_access" : false };
  errorMsg = '';

  constructor(
    public dialogRef: MatDialogRef<openEditProductDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}
}


// OPEN ORDER TIMELINE DIALOG
@Component({
  selector: 'dialog-order-timeline',
  templateUrl: 'dialog-order-timeline.html',
})
export class openOrderTimeline {
  orderTimeline: any;

  constructor(
    public dialogRef: MatDialogRef<openOrderTimeline>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {
        this.orderTimeline = data['orderTimeline'];
    }
}
