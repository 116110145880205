import { Component, OnInit } from '@angular/core';
import { ApiHttpService } from 'src/app/_service/api-http.service';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {

  order_id = "";
  order_details     = { order_id:'',book_date:'',lastupdate:'',  order_status:'',order_amount : 0.0, order_tax:0.0, orderAmountInWords:''};
  order_by          = { id: '', business_name: '',business_contact: '',business_address:'',business_pincode:'',business_gst_no:'',cityState: ''};
  order_to          = { id: '', business_name: '',business_contact: '',business_address:'',business_pincode:'',business_gst_no:'',cityState: ''};
  order_book_by     = { id: '',fullname: '',usertype: ''};
  orderSummary      = [];

  constructor(public dialog: MatDialog,private route: ActivatedRoute,
    private apiHttp:ApiHttpService) {

      this.order_id = this.route.snapshot.paramMap.get('order_id');
      this.singleOrderView();
   }

  ngOnInit() {
  }

  singleOrderView() {

    let postParamData = {};
    postParamData['order_id'] = this.order_id;

    this.apiHttp.singleOrderView(postParamData)
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {
        
        if(data['status'] == 100) {

          this.order_details             = data['data']['order_details'];
          this.order_by                  = data['data']['order_by'];
          this.order_to                  = data['data']['order_to'];
          this.order_book_by             = data['data']['order_book_by'];
          this.orderSummary              = data['data']['order_summary'];

        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }

    });
  }

}
