import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiHttpService } from 'src/app/_service/api-http.service';

@Component({
  selector: 'app-base-order-list',
  templateUrl: './base-order-list.component.html'
})
export class BaseOrderListComponent implements OnInit {

  loggedInUsertype = '';
  orderList        = [];
  postParamData    = {};

  getData = false;

  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  scrollLimit = "0";

  constructor(private router: Router,
              private apiHttp:ApiHttpService) { 
    this.loggedInUsertype = localStorage.getItem('sales_supply');

    // set postParamData as per condition:
    this.postParamData = {};
    this.getOrderList();
  }

  ngOnInit() {
  }

  getOrderList() {
    console.log("Limit: "+this.scrollLimit);
    this.scrollLimit = (this.scrollLimit+10);
    
    this.apiHttp.getOrderList(this.postParamData)
      .subscribe(data=> {
  
        let keys = Object.keys(data);
        if(keys.length != 0) {
  
          if(data['status'] == 100) {
            this.orderList = data['data']; 
            this.getData   = true;
          } else if(data['status'] == 101) {
            console.log("Error in order list");
          } else if(data['status'] == 103) {
            this.apiHttp.logoutUser();
          }
        }
  
      });
  }

  goToOrderView(order_id) {
    let rURL;
    if(this.loggedInUsertype == "ADMIN"){
      rURL = "/admin/orderview/"+order_id;
    } else if(this.loggedInUsertype == "SUPPLY") {
      rURL = "/supply/orderview/"+order_id;
    } 
    this.router.navigateByUrl(rURL);
  };

}
