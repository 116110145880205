import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiHttpService } from '../../../../_service/api-http.service';
import { HttpClient } from '@angular/common/http';
export interface DialogData {
  
}
@Component({
  selector: 'app-supply-profile-leftside',
  templateUrl: './supply-profile-leftside.component.html'
})
export class SupplyProfileLeftsideComponent implements OnInit {
 
  headerInfo        = {business_logo:'',business_name:'',cityState:'',usertype:'',status:'',business_contact:'',fullname:'',business_code:'',id:''};
  lat = '';
  long = '';
  member_id       = '';
  getData:boolean = false;

  postParamUserProfile = {};
  constructor(private router: Router,
    private apiHttp:ApiHttpService,
    private route: ActivatedRoute,
    public dialog: MatDialog) { 

    this.member_id = this.route.snapshot.paramMap.get('member_id');
    this.getUserProfile();
  }

  ngOnInit() {
  }

  openPhotoUploadSupplyDialog(): void {
    const dialogRef = this.dialog.open(openPhotoUploadSupplyDialog, {
      width: '500px',
      data:{member_id:this.member_id}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getUserProfile();
    });
  }

  openMap(): void {
    const dialogRef = this.dialog.open(openMap, {
      width: '1000px',
      data:{lat:this.lat,long:this.long}
    });
  }


  openSendMessageSupplyDialog(): void {
    const dialogRef = this.dialog.open(openSendMessageSupplyDialog, {
      width: '800px',
    });
  }


  // GET USER TYPE DETAILS
  getUserProfile(): void {

    this.postParamUserProfile['member_id'] = this.member_id;
    this.apiHttp.getSupplyProfile(this.postParamUserProfile)
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {

        if(data['status'] == 100) {

          this.headerInfo = data['data']['headerInfo'];
          this.lat = this.headerInfo['lat'];
          this.long = this.headerInfo['long'];
          this.getData    = true;

        } else if(data['status'] == 101) {
          this.router.navigate(['/admin/supplylist/1']);
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }

    });
  }
}

// UPLOAD PHOTO DIALOG
@Component({
  selector: 'dialog-photo-upload',
  templateUrl: '../../../edit-popup/dialog-photo-upload.html',
})
export class openPhotoUploadSupplyDialog {
  
  uploadTitle = "UPLOAD LOGO";
  errorMsg  = "";
  uploadBtn = true;

  member_id = "";
  businessLogo:File;

  constructor(
    public dialogRef: MatDialogRef<openPhotoUploadSupplyDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,private router: Router,
    private apiHttp:ApiHttpService, private snackBar: MatSnackBar
    ) {
      this.member_id = data['member_id'];
    }
  
  getSelectedFile($event) {
    this.businessLogo = $event.target.files[0];  
    this.uploadBtn = false;
  }  

  uploadFile() {
    this.uploadBtn = true;
    const _formData = new FormData();

    _formData.append('token',localStorage.getItem('token'));  
    _formData.append('user_id',localStorage.getItem('user_id'));  
    _formData.append('member_id',this.member_id);  

    _formData.append('file', this.businessLogo, this.businessLogo.name);  

    this.apiHttp.uploadUserPhoto(_formData)
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {

        if(data['status'] == 100) {

          this.snackBar.open("Upload successfully.!",'', {
            duration: 10000,
            horizontalPosition: 'center',
            verticalPosition : 'top'
          });

          this.dialogRef.close();
          this.router.navigate(['/admin/supplyprofile/'+this.member_id]);
          
        } else if(data['status'] == 101) {
          this.errorMsg = data['data'];           
        }
      }

    });
  }

}


    @Component({
      selector: 'dialog-open-map',
      templateUrl: 'dialog-open-map.html',
    })
    export class openMap {
     
      lat : any;
      long : any;
     
      constructor(
        public dialogRef: MatDialogRef<openMap>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        )
        {
          this.lat = +data['lat'];
          this.long = +data['long'];
        }
        
    }
  
  // SEND MESSAGE DIALOG
@Component({
  selector: 'dialog-send-message',
  templateUrl: '../../../edit-popup/dialog-send-message.html',
})
export class openSendMessageSupplyDialog {

  constructor(
    public dialogRef: MatDialogRef<openSendMessageSupplyDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}
  }

