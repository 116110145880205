import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiHttpService } from '../../../../_service/api-http.service';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-user-sales-history',
  templateUrl: './user-sales-history.component.html'
})
export class UserSalesHistoryComponent implements OnInit {
  public _barChartOptions:any = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public _barChartLabels:string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July','Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  public _barChartType:string = 'bar';
  public _barChartLegend:boolean = true;

  public _barChartData:any[] = [
    {data: [65, 49, 100, 81, 36, 55, 50, 102, 85, 96, 24, 45], label: 'Monthly Sales'}
  ];

  public _chartColors: any[] = [
    {
      backgroundColor:["#f88a81", "#ffe8a2", "#f88a81", "#ffe8a2", "#f88a81", '#ffe8a2', "#f88a81",  "#ffe8a2", "#f88a81", "#ffe8a2", "#f88a81", '#ffe8a2']
    }];

// events
public chartClicked(e:any):void {
  console.log(e);
}

public chartHovered(e:any):void {
  console.log(e);
}


  member_id = '';
  postParamUserProfile = {};
  salesInfo = [];

  constructor(private router: Router, private apiHttp: ApiHttpService, private route: ActivatedRoute, public dialog: MatDialog) {
    this.member_id = this.route.snapshot.paramMap.get('id');
    this.getyearlySalesHistory();
   }

  ngOnInit() {
  }
  getyearlySalesHistory(): void {

    this.postParamUserProfile['member_id'] = this.member_id;
    this.apiHttp.getyearlySalesHistory(this.postParamUserProfile)
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {

        if(data['status'] == 100) {
          this.salesInfo = data['data'];
        } else if(data['status'] == 101) {
          this.router.navigate(['/admin/saleslist/1']);
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }

    });
  }

}
