import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-deficitstock',
  templateUrl: './deficitstock.component.html',
  styleUrls: ['./deficitstock.component.css']
})
export class DeficitstockComponent implements OnInit {
  deficitlist = [  
    {photo:"../assets/images/chakali.png", title:"Chakali Mix (200 gm)" , current_stock:"10,000" , order_stock:"11,000", deficit_stock:"1,000", stock_value:"12,000"},
    {photo:"../assets/images/dahiwada.png", title:"Dahiwada Mix" , current_stock:"48,000" , order_stock:"50,000", deficit_stock:"2,000", stock_value:"10,000"},
    {photo:"../assets/images/dhokla.png", title:"Dhokla Mix" , current_stock:"1,20,000" , order_stock:"1,50,000", deficit_stock:"30,000", stock_value:"10,000"},
    {photo:"../assets/images/idli.png", title:"Idli Instant Mix" , current_stock:"12,000" , order_stock:"20,000", deficit_stock:"8,000", stock_value:"10,000"},
    {photo:"../assets/images/singhada.png", title:"Singhada Mix" , current_stock:"91,000" , order_stock:"1,02,000", deficit_stock:"11,000", stock_value:"15,000"}
    
  ];
  constructor() { }

  ngOnInit() {
  }

}
