import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ApiHttpService } from '../../../_service/api-http.service';
import { Router } from '@angular/router';
// tslint:disable-next-line:no-empty-interface
export interface DialogData {
}

@Component({
  selector: 'app-product-category',
  templateUrl: './product-category.component.html'
})
export class ProductCategoryComponent implements OnInit {

  postParamFormData   = {category_title:'',hsn_sac_code:'',gst_per:''};
  productCategoryList = [];

  getData:boolean = false;

  constructor(public dialog: MatDialog,
    private apiHttp:ApiHttpService) {
      this.getCategoryList();
  }

  ngOnInit() {
  }

  openAddCategoryDialog(): void {
    const dialogRef = this.dialog.open(openAddCategoryDialog, {
      width: '800px'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getCategoryList();
    });
  }

  openUpdateCategoryDialog(index): void {
    const dialogRef = this.dialog.open(openUpdateCategoryDialog, {
      width: '800px',
      data:{singleCategoryDetails:this.productCategoryList[index]}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getCategoryList();
    });
  }

  getCategoryList() {

    this.apiHttp.getProductCategoryList(this.postParamFormData)
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {

        if(data['status'] == 100) {

          this.productCategoryList = data['data'];
          this.getData = true;

        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }

    });
  }
}

// OPEN ADD PRODUCT CATEGORY DIALOG
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dialog-add-category',
  templateUrl: 'dialog-add-category.html',
})
export class openAddCategoryDialog {

  postParamFormData = {category_title:'',hsn_sac_code:'',gst_per:''};
  errorMsg = [];

  constructor(
    public dialogRef: MatDialogRef<openAddCategoryDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private apiHttp:ApiHttpService,private router: Router,
    private snackBar: MatSnackBar
    ) {}

    addProductCategory() {

      this.apiHttp.addProductCategory(this.postParamFormData)
      .subscribe(data=> {
  
        let keys = Object.keys(data);
        if(keys.length != 0) {
  
          if(data['status'] == 100) {
  
            this.snackBar.open("Category added successfully.!",'', {
              duration: 10000,
              horizontalPosition: 'center',
              verticalPosition : 'top'
            });

            this.dialogRef.close();

          } else if(data['status'] == 101) {
            this.errorMsg = data['data'];           
          } else if(data['status'] == 103) {
            this.apiHttp.logoutUser();
          }
        }
  
      });
    }
  }

// OPEN UPDATE PRODUCT CATEGORY DIALOG
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dialog-update-category',
  templateUrl: 'dialog-update-category.html',
})
export class openUpdateCategoryDialog {

  postParamFormData = {category_title:'',hsn_sac_code:'',gst_per:''};
  errorMsg = ""
  postParamSupplyList = {};
  productCategoryList = {};

  constructor(public dialogRef: MatDialogRef<openUpdateCategoryDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private apiHttp:ApiHttpService,private router: Router,
    private snackBar: MatSnackBar
    ) {
      this.postParamFormData = data['singleCategoryDetails'];
    }

    updateProductCategory() {

      this.apiHttp.updateProductCategory(this.postParamFormData)
      .subscribe(data=> {
  
        let keys = Object.keys(data);
        if(keys.length != 0) {
  
          if(data['status'] == 100) {
  
            this.snackBar.open("Category update successfully.!",'', {
              duration: 10000,
              horizontalPosition: 'center',
              verticalPosition : 'top'
            });

            this.dialogRef.close();

          } else if(data['status'] == 101) {
            this.errorMsg = data['data'];           
          } else if(data['status'] == 103) {
            this.apiHttp.logoutUser();
          }
        }
  
      });
    }

  // searchList(e:any){

  //   let searchString = e.target.value;
  //   if(searchString.length > 2)
  //   {
  //     this.postParamFormData['searchString'] = searchString;
  //     console.log(this.postParamFormData);
  //     this.apiHttp.getProductCategoryList(this.postParamFormData)
  //     .subscribe(data=> {

  //       let keys = Object.keys(data);
  //       if(keys.length != 0) {

  //         if(data['status'] == 100) {
  //           this.productCategoryList = data['data'];
  //         } else if(data['status'] == 103) {
  //           this.apiHttp.logoutUser();
  //         }
  //       }

  //   });
  //   }
  //   }

  }
