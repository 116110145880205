import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { routingComponents, AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
// import { FileSelectDirective } from 'ng2-file-upload';

import { ChartsModule } from 'ng2-charts';

// tslint:disable-next-line:max-line-length
import { MatCheckboxModule, MatButtonModule, MatNativeDateModule, MatDatepickerModule, MatToolbarModule, MatCardModule, MatInputModule, MatListModule} from '@angular/material';
import { MatTabsModule } from '@angular/material/tabs';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { HeaderComponent } from './_root-component/header/header.component';

import { ApiHttpService } from './_service/api-http.service';
import { HttpClientModule } from '@angular/common/http';
import { AuthGuard } from './_service/auth.guard';
import { Constants } from './_service/constants';
import { DialogAddSupplyChain, DialogAddUserChain, openEditSupplyListDialog, openEditSalesListDialog, openDeleteSalesListDialog,openDeleteSupplyListDialog } from './admin/settings/usertypemanagement/usertypemanagement.component';

import { LoadingComponent } from './_component/loading/loading.component';

import { DialogEditBasicInfo, openEditBankDetailsDialog, openEditLoginInfoDialog, openSendMessageDialog,
  openDeleteDialog, openUpdateTeamMemberDialog} from './_component/user-chain/user-profile/user-profile.component';

// tslint:disable-next-line:max-line-length
import { openEditBankDetailsSupplyDialog, openEditLoginInfoSupplyDialog,  openUpdateBusinessInfoSupplyDialog, openUpdateServiceProviderSupplyDialog, openUpdateBaSicInfoSupplyDialog, openDeleteSupplyDialog} from './_component/supply-chain/supply-profile/supply-profile.component';
import { openPhotoUploadDialog } from './_component/user-chain/user-profile/user-profile-header/user-profile-header.component';

import { UserProfileHeaderComponent } from './_component/user-chain/user-profile/user-profile-header/user-profile-header.component';

import { MatSnackBarModule} from '@angular/material/snack-bar';
import { openEditProductDialog, openOrderTimeline } from './_component/order/order-view/order-view.component';

import { openUpdateProductDetailsDialog, openAddProductStockDialog, openUploadProductPhoto } from './_component/product/product-profile/product-profile.component';
import { openAddCategoryDialog, openUpdateCategoryDialog } from './_component/product/product-category/product-category.component';
import { DatePipe } from '@angular/common';
import { AgmCoreModule } from '@agm/core';
import { openPhotoUploadSupplyDialog, openMap, openSendMessageSupplyDialog } from './_component/supply-chain/supply-profile/supply-profile-leftside/supply-profile-leftside.component';
import { openAddRateCategoryDialog,openEditRateList, openUploadExcel } from './_component/product/rate-category/rate-category.component';
import { openPhotoUploadAdminAccountDialog, openEditLoginInfoAdminAccountDialog } from './admin/account-admin/account-admin.component';
import { openOrderCheckoutDialog } from './_component/order/order-book/order-book.component';

// tslint:disable-next-line:max-line-length
export const matComponents = [ MatNativeDateModule, MatDatepickerModule, MatIconModule, MatButtonModule, MatCheckboxModule, MatToolbarModule, MatCardModule, MatFormFieldModule, MatInputModule, MatListModule, MatTabsModule, MatStepperModule, MatSelectModule, MatRadioModule ];

@NgModule({
  declarations: [
    AppComponent,
    // FileSelectDirective,
    routingComponents,
    HeaderComponent,
   
    DialogEditBasicInfo,
    openEditBankDetailsDialog,
    openEditLoginInfoDialog,
    openSendMessageDialog,
    openDeleteDialog,
    openPhotoUploadDialog,
    openUpdateTeamMemberDialog,

    openEditBankDetailsSupplyDialog,
    openEditLoginInfoSupplyDialog,
    openSendMessageSupplyDialog,
    openUpdateBusinessInfoSupplyDialog,
    openPhotoUploadSupplyDialog,
    openUpdateServiceProviderSupplyDialog,
    openUpdateBaSicInfoSupplyDialog,
    openDeleteSupplyDialog,
    openMap,
    
    openEditProductDialog,
    openOrderTimeline,
    openOrderCheckoutDialog,
    
    openUpdateProductDetailsDialog,
    openUploadProductPhoto,
    openAddProductStockDialog,
    openAddCategoryDialog,
    openUpdateCategoryDialog,
    openAddRateCategoryDialog,
    openEditRateList,
    openUploadExcel,
    LoadingComponent,
    UserProfileHeaderComponent,
   
    DialogAddSupplyChain,
    DialogAddUserChain,
    openEditSupplyListDialog,
    openEditSalesListDialog,
    openDeleteSalesListDialog,
    openDeleteSupplyListDialog,
    openPhotoUploadAdminAccountDialog,
    openEditLoginInfoAdminAccountDialog,
    
    
  ],
  // tslint:disable-next-line:max-line-length
  entryComponents: [DialogAddSupplyChain, DialogAddUserChain, DialogEditBasicInfo, openEditBankDetailsDialog, openEditLoginInfoDialog, openSendMessageDialog, openDeleteDialog, openUpdateTeamMemberDialog, openPhotoUploadDialog, openEditBankDetailsSupplyDialog, openEditLoginInfoSupplyDialog, openSendMessageSupplyDialog, openUpdateBusinessInfoSupplyDialog, openPhotoUploadSupplyDialog, openUpdateServiceProviderSupplyDialog, openUpdateBaSicInfoSupplyDialog, openEditProductDialog, openOrderTimeline, openUpdateProductDetailsDialog, openAddProductStockDialog, openAddCategoryDialog, openUpdateCategoryDialog,openEditSupplyListDialog, openEditSalesListDialog,openDeleteSalesListDialog,openDeleteSupplyListDialog, openUploadProductPhoto, openDeleteSupplyDialog,openAddRateCategoryDialog,openEditRateList,openPhotoUploadAdminAccountDialog,openEditLoginInfoAdminAccountDialog,openOrderCheckoutDialog, openUploadExcel,openMap],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    ChartsModule,
    matComponents,
    HttpClientModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBG2TTJx80wMb9ca1AUlqj1S_jTi2xN5_g'
    })
  ],

  exports: [],

  providers: [ApiHttpService,AuthGuard,Constants,DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
