import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-sales',
  templateUrl: './dashboard-sales.component.html',
  styleUrls: ['./dashboard-sales.component.css']
})
export class DashboardSalesComponent implements OnInit {

  dashboardHeader = { loginuser:'Deepali Fepale',thought:"One best book is equal to a hundred good friends, but one good friend is equal to a library", lastlogin:"3 Oct 2018, 12:30 pm"};

  salesFigureData = { todays_sales:"12,000", monthly_sales:"2,15,000"};

  memberList      = [
                      {photo:"../assets/images/boy.png",name:"Aakash Rajesh Bodkhe",designation:"Regional Sales Manager",lastactive:"308, Tulsikrupa, Kapse Chowk, Nagpur (3 days Ago)",dailysales:"2154", monthlysales:"1500",todaysorderbooked:"150",totalorderbooked:"1300"},
                      {photo:"../assets/images/girl.png",name:"Anjali Rajesh Bodkhe",designation:"Area Sales Manager",lastactive:"308, Tulsikrupa, Kapse Chowk, Nagpur (3 days Ago)",dailysales:"2154", monthlysales:"1500",todaysorderbooked:"150",totalorderbooked:"1300"},
                      {photo:"../assets/images/girl1.png",name:"Shweta Shambhargade",designation:"Sales Representative",lastactive:"308, Tulsikrupa, Kapse Chowk, Nagpur (3 days Ago)",dailysales:"2154", monthlysales:"1500",todaysorderbooked:"150",totalorderbooked:"1300"}

                    ];

  orderList = [ {id:1023,  orderby:{name:"R.K.Enterprises", address:"Badnera, Maharashtra"},   orderbookby:{name:"Anjali Bodkhe", address:"Badnera, Maharashtra"}, bookdate:"29 Aug", amount:"17,000", lastupdated: "20 Sep 2018, 12:44 pm", status:"DELIVERED"},
                {id:2546,  orderby:{name:"Anjali Traders", address:"Amravati, Maharashtra"}, orderbookby:{name:"Neha Patil", address:"Badnera, Maharashtra"},bookdate:"30 Aug",amount:"31,000", lastupdated: "20 Sep 2018, 12:44 pm", status:"CONFIRM"},
                {id:2424,  orderby:{name:"Amarnath Kirana", address:"Nagpur, Maharashtra"}, orderbookby:{name:"Prashant Fepale", address:"Nagpur, Maharashtra"}, bookdate:"31 Aug",amount:"10,000", lastupdated: "20 Sep 2018, 12:44 pm", status:"DISPATCHED"},
                {id:4856,  orderby:{name:"Gurudev Kirana", address:"Aurangabad, Maharashtra"},  orderbookby:{name:"Parth Fepale", address:"Nagpur, Maharashtra"}, bookdate:"03 Sep",amount:"13,500", lastupdated: "20 Sep 2018, 12:44 pm", status:"PROCESSING"},
                {id:9616,  orderby:{name:"Vaibhavlaxmi Store", address:"Badnera, Maharashtra"},  orderbookby:{name:"Harsh Chaudhari", address:"Amravati, Maharashtra"}, bookdate:"03 Sep", amount:"19,100", lastupdated: "20 Sep 2018, 12:44 pm", status:"CANCEL"}
              ];





  // Bar graph 

  public barChartOptions:any = {
    scaleShowVerticalLines: false,
    responsive: true
  };

  public barChartType:string = 'bar';
  public barChartLegend:boolean = true;
  public chartColors: any[] = [{ 
    backgroundColor:["#f88a81", "#ffe8a2", "#f88a81", "#ffe8a2", "#f88a81", '#ffe8a2', "#f88a81", "#ffe8a2", "#f88a81", "#ffe8a2", "#f88a81", '#ffe8a2'] 
  }];


  public barChartLabels:string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  
  public barChartData:any[] = [{
    data: [65, 49, 100, 81, 36, 55, 50, 37, 85, 64, 42, 60], 
    label: 'Yearly Sales'
  }];
  
 // events
 public chartClicked(e:any):void {
  console.log(e);
}

public chartHovered(e:any):void {
  console.log(e);
}
  constructor() { }

  ngOnInit() {
  }

}
