import { Component, OnInit, NgZone } from '@angular/core';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiHttpService } from '../../../../_service/api-http.service';

@Component({
  selector: 'app-salestracking',
  templateUrl: './salestracking.component.html',
  styleUrls: ['./salestracking.component.css']
})
export class SalestrackingComponent implements OnInit {

  public latitude: number;
  public longitude: number;
  public maxSpeed: number;
  public zoom: number;
  public polyline: Array<any>;
  public polylines: Array<any>;
  postParamUserProfile = {};

  headerInfo = {};
  member_id  = '';

  daydate         = "...";
  startdaytime    = "...";
  enddaytime      = "...";
  middleLocation  = {};
  travalledDistance = '';
  userActivity      = [];
  
  constructor(
    private mapsAPILoader: MapsAPILoader,
    private apiHttp:ApiHttpService,
    private route: ActivatedRoute,
  ) {
      this.member_id = this.route.snapshot.paramMap.get('id');
      this.getLocationList();
  }
  
  ngOnInit() {
    this.zoom = 15;
    this.maxSpeed = 40;    

    this.latitude = 21.146027;
    this.longitude = 79.122750;

  }

  // GET USER TYPE DETAILS
  getLocationList(): void {

    this.postParamUserProfile['member_id'] = this.member_id;
    this.apiHttp.getLocationList(this.postParamUserProfile)
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {

        if(data['status'] == 100) {

          this.daydate      = data['data']['daydate'];
          this.startdaytime = data['data']['startdaytime'];
          this.enddaytime   = data['data']['enddaytime'];

          this.latitude  = +data['data']['middleLocation']['lat'];
          this.longitude = +data['data']['middleLocation']['long'];

          this.userActivity      = data['data']['activityList'];
          this.travalledDistance = data['data']['travelledDistance'];

          this.polyline  = data['data']['locationList'];
          
          this.polylines = this.rebuildPolylines();
          this.setCurrentPosition();
          this.mapsAPILoader.load().then(() => {
      
          });

        } else if(data['status'] == 101) {
          // this.router.navigate(['/admin/saleslist/1']);
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }
    });
  }
  
  private rebuildPolylines() {

    let polylines = [];
    let newPolyline = {path: [], color: '#EA4335'};
    for (let point of this.polyline) 
    {
        point = {lat : +point['lat'],long: +point['long']};
        newPolyline.path.push(point);
    }

    polylines.push(newPolyline);
    
    return polylines;
  }

  private setCurrentPosition() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
      });
    }
  }
}

