import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-supply',
  templateUrl: './account-supply.component.html'
})
export class AccountSupplyComponent implements OnInit {

  headerInfo   = {username:"pgfepale"};

  businessInfo = {business_code:"25175",business_name:"Sreyah Technologies",business_email:"support@sreyah.co.in",business_contact:"0712-2774770",business_address:"2, Lane Number 2, Laxman Nagar, Nagpur, Maharashtra 411045",business_city:"Nagpur",business_state:"Maharashtra",business_pincode:"420008",business_pan_no:"ABHJPF1160E",gst_no:"SD15478D56652"};

  ownerInfo    = {fullname:"Prashant Fepale",mobile1:"9049494771",pan_no:"BDCF2584F",adhaar_no:"874585416012"};

  bankDetails  = {bank_name:"State Bank of India",account_name:"Savings Account",account_number:"49401503779",ifsc_code:"SBI0215001"};

  constructor() { }

  ngOnInit() {
  }

}
