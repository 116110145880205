import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiHttpService } from '../../_service/api-http.service';

@Component({
  selector: 'app-userchainform-admin',
  templateUrl: './userchainform-admin.component.html',
  styleUrls: ['./userchainform-admin.component.css']
})

export class UserchainformAdminComponent implements OnInit {

  usertype_id     = '';
  userTypeDetails = {};
  errorMsg        = [];

  postParamFormData = {fullname:'',gender:'',mobile1:'',mobile2:'',email:'',dob:'',address:'',pincode:'',username:'',password:''};
  postParamUserType = {};
  
  constructor(private router: Router,
              private apiHttp:ApiHttpService,
              private route: ActivatedRoute,
              private snackBar: MatSnackBar) { 

    this.usertype_id = this.route.snapshot.paramMap.get('usertype_id');
    this.postParamFormData['usertype_id'] = this.usertype_id;
    this.getUserTypeDetails();    
  }

  ngOnInit() {
  }

  // GET USER TYPE DETAILS
  getUserTypeDetails(): void {

    this.postParamUserType['usertype_id'] = this.usertype_id;
    this.apiHttp.getSalesSupplyChainType(this.postParamUserType)
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {

        if(data['status'] == 100) {
          this.userTypeDetails = data['data'];
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }

      }
    });
  }

  // HTTP ADD SUPPLY USER TO SERVER
  httpAddSalesUser() {

    console.log(this.postParamFormData);
    this.apiHttp.addSalesUser(this.postParamFormData)
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {

        if(data['status'] == 100) {

          this.openSnackBar("User added successfully.");
          let rUrl = '/admin/saleslist/'+this.usertype_id;
          this.router.navigateByUrl(rUrl);

        } else if(data['status'] == 101) {
          this.errorMsg = data['data'];
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }

      }
    });
  }

  openSnackBar(message: string) {
    this.snackBar.open(message,'', {
      duration: 10000,
      horizontalPosition: 'center',
      verticalPosition : 'top'
    });
  }

}
