import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  template: `
          <div class="row">
          <div class="col-md-12 ms-auto" style="text-align:center;margin-top:20%">
            <p>Can you please stay cool. I just getting data from server.</p>
            <img src="../assets/images/loader.gif" alt="">
          </div>
          </div>
        `,
})
export class LoadingComponent implements OnInit {

  constructor() { }
  ngOnInit() {
  }

}
