import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { ApiHttpService } from '../_service/api-http.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginData = {passwd:'',username:''};

  errorMsg  = '';
  loaderBar = false;

  constructor(private router: Router,private apiHttp:ApiHttpService) {
    localStorage.removeItem('token')
    localStorage.clear();
  }

  ngOnInit() {
  }

  checkLogin() {

    this.loaderBar = true;
    this.apiHttp.postLoginCheck(this.loginData)
    .subscribe(data=> {
      
      let keys = Object.keys(data);
      if(keys.length != 0) {
      
        if(data['status'] == 100) {

          if(data['data']['sales_supply'] == "ADMIN") {

            localStorage.setItem('sales_supply', data['data']['sales_supply']);
            localStorage.setItem('user_id', data['data']['id']);
            localStorage.setItem('fullname', data['data']['fullname']);

            localStorage.setItem('usertype_id', data['data']['usertype_id']);
            localStorage.setItem('token', data['data']['token']);
            localStorage.setItem('photo', data['data']['photo']);

            this.router.navigate(['/admin/dashboard']);

          } else if(data['data']['sales_supply'] == "SUPPLY"){

            localStorage.setItem('sales_supply', data['data']['sales_supply']);
            localStorage.setItem('user_id', data['data']['id']);
            localStorage.setItem('fullname', data['data']['fullname']);

            localStorage.setItem('usertype_id', data['data']['usertype_id']);
            localStorage.setItem('token', data['data']['token']);
            localStorage.setItem('photo', data['data']['photo']);

            this.router.navigate(['/supply/dashboard']);

          } else if(data['data']['sales_supply'] == "SALES"){

            localStorage.setItem('sales_supply', data['data']['sales_supply']);
            localStorage.setItem('user_id', data['data']['id']);
            localStorage.setItem('fullname', data['data']['fullname']);

            localStorage.setItem('usertype_id', data['data']['usertype_id']);
            localStorage.setItem('token', data['data']['token']);
            localStorage.setItem('photo', data['data']['photo']);

            this.router.navigate(['/sales/dashboard']);
          } else {
            this.loaderBar = false;
            this.errorMsg = "Invalid username/password.";
          }
        } else {
          this.loaderBar = false;
          this.errorMsg = "Invalid username/password.";
        }
      }
    });
  }
}
