import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['../login.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  loaderBar = false;
  
  constructor() { }

  ngOnInit() {
  }

}
