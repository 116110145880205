import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html'
})
export class ReportsComponent implements OnInit {

orderList = [ { id:"2017",orderdate:"",orderby:{name:"Anjali Bodkhe",address:"Badnera, Maharashtra"},orderto:{name:"Shweta Shambhargade",address:"Nagpur, Maharashtra",amount:"17000"},status:"DISPATCHED"},
              { id:"2017",orderdate:"",orderby:{name:"Anjali Bodkhe",address:"Badnera, Maharashtra"},orderto:{name:"Shweta Shambhargade",address:"Nagpur, Maharashtra",amount:"17000"},status:"CONFIRM"}

            ];

  public _barChartOptions:any = {
    scaleShowVerticalLines: false,
    responsive: true
  };

  public _barChartLabels:string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July','Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  public _barChartType:string = 'bar';
  public _barChartLegend:boolean = true;

  public _barChartData:any[] = [
    {data: [65, 49, 100, 81, 36, 55, 50, 102, 85, 96, 24, 45], label: 'Monthly Sales'}
  ];
 
  public _chartColors: any[] = [
    { 
      backgroundColor:["#f88a81", "#ffe8a2", "#f88a81", "#ffe8a2", "#f88a81", '#ffe8a2', "#f88a81",  "#ffe8a2", "#f88a81", "#ffe8a2", "#f88a81", '#ffe8a2'] 
    }];

// events
public chartClicked(e:any):void {
  console.log(e);
}

public chartHovered(e:any):void {
  console.log(e);
}

  constructor() { }

  ngOnInit() {
  }

}
