import { Injectable } from '@angular/core';

@Injectable()
export class Constants {

    // BASE SERVER URL
    public api_key  = "SUPPLY-API";

    public _baseurl = "http://sales.sreyah.net/_backend/api/";
    // public _baseurl = "http://192.168.1.102/sales_and_supply/web/api/";

    public login                    = this._baseurl+"usersLogin";
    public adminDashboard           = this._baseurl+"webAdminDashboard";
    public userTypeAdd              = this._baseurl+"insertUserType";
    public updateUserType           = this._baseurl+"updateUserType";
    public deleteUserType           = this._baseurl+"deleteUserType";

    public getSalesSupplyChain      = this._baseurl+"getUsertype";
    public addSupplyUser            = this._baseurl+"addSupplyUser";
    public getSupplyList            = this._baseurl+"getSupplyUserList";
    public getServiceProviderList   = this._baseurl+"getServiceProviderList";
    public updateServiceProvider    = this._baseurl+"updateServiceProvider";

    public profileSupplyUser        = this._baseurl+"getSupplyUserProfile";
    public updateSupplyBusinessInfo = this._baseurl+"updateSupplyBusinessInfo";
    public updateUserLogin          = this._baseurl+"updateUserLogin";
    public updateSupplyOwnerInfo    = this._baseurl+"updateSupplyOwnerInfo";
    public updateBankDetails        = this._baseurl+"updateBankDetails";

    public getSalesUserList         = this._baseurl+"getSalesUserList";
    public addSalesUser             = this._baseurl+"addSalesUser";
    public profileHeaderSalesUser   = this._baseurl+"getSalesUserProfileHeader";
    public profileSalesUser         = this._baseurl+"getSalesUserProfile";
    public updateSalesBasicInfo     = this._baseurl+"updateSalesBasicInfo";
    public getLocationList          = this._baseurl+"getLocationTracking";
    public salesProfileActivityLog  = this._baseurl+"salesProfileActivityLog";
    public getyearlySalesHistory    = this._baseurl+"getyearlySalesHistory";

    public addProductCategory       = this._baseurl+"addProductCategory";
    public getProductCategoryList   = this._baseurl+"getProductCategoryList";
    public updateProductCategory    = this._baseurl+"updateProductCategory";

    public addProduct               = this._baseurl+"addProduct";
    public getProductList           = this._baseurl+"getProductList";
    public getProductProfile        = this._baseurl+"productProfile";
    public updateProductInfo        = this._baseurl+"updateProductInfo";

    public addRateCategory          = this._baseurl+"addProductRateCategory";
    public getRateCategoryList      = this._baseurl+"getProductRateCategoryList";
    public getRateListByCategory    = this._baseurl+"getProductCategorySellRateList";
    public updateRateListSingle     = this._baseurl+"getProductCategorySellRateList";

    public addFactoryStock          = this._baseurl+"addFactoryStock";
    public getStockDetails          = this._baseurl+"getStockDetails";

    public getProductListNoLimit     = this._baseurl+"getAllProductList";
    public getOrderByOrderTo         = this._baseurl+"getOrderByOrderToInfo";
    public getSingleProductInfoOrder = this._baseurl+"getSingleProductInfoOrder";
    public placeOrder                = this._baseurl+"placeOrder";
    public getOrderList              = this._baseurl+"getOrderList";

    public singleOrderView           = this._baseurl+"singleOrderView";

    // upload url
    public uploadUserPhoto          = this._baseurl+"uploadUserPhoto";
    public uploadProductImage       = this._baseurl+"uploadProductImage";
    public uploadExcelPriceList     = this._baseurl+"uploadExcelPriceList";
    public downloadRateSampleExcel  = this._baseurl+"downloadRateSampleExcel";
}
