import { Component } from '@angular/core';
import { ApiHttpService } from './_service/api-http.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  constructor(public apiHttp: ApiHttpService) {
  }
  
}
