import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiHttpService } from '../../../_service/api-http.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
export interface DialogData {
  
}
@Component({
  selector: 'app-order-book',
  templateUrl: './order-book.component.html',
  styleUrls: ['./order-book.component.css']
})
export class OrderBookComponent implements OnInit {

  productList       = [];
  getData:boolean   = false;
  getDataOrderUsers = false; 
  loggedInUsertype  = '';
  addProductToCart  = {};

  prepareOrder  = {};
  orderItemList = [];
  orderAmount   = 0;
  orderTax      = 0;
  orderBy       = "";
  orderByOrderInfo = [];

  postParamOrderByOrderTo = {};
  postParamSingleProduct = {};

  constructor(private router: Router,private route: ActivatedRoute,
              private apiHttp:ApiHttpService,private snackBar: MatSnackBar,public dialog: MatDialog) {
    
    this.orderBy = this.route.snapshot.paramMap.get('id');
                
    this.getServiceProviderDetails();    
    this.getProductList();
  }

  ngOnInit() {
  }

  openOrderCheckoutDialog(): void {
    const dialogRef = this.dialog.open(openOrderCheckoutDialog, {
      width: '1000px',
      data : { prepareOrder:this.prepareOrder }
    });
    // console.log("prepareorder:"+JSON.stringify(this.prepareOrder));
  }


  getServiceProviderDetails() {
    this.postParamOrderByOrderTo['order_by'] = this.orderBy;
    this.apiHttp.getOrderByOrderTo(this.postParamOrderByOrderTo)
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {
        
        if(data['status'] == 100) {
          this.orderByOrderInfo = data['data'];
          this.getDataOrderUsers = true;
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }

    });
  }

  getProductList() {
    this.apiHttp.getProductListNoLimit({})
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {
        
        if(data['status'] == 100) {
          this.productList = data['data'];
          this.getData = true;
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }

    });
  }

  prepareOrderUpdate() {
    this.prepareOrder['order_book_by'] = localStorage.getItem('user_id');
    this.prepareOrder['order_user']    = this.orderByOrderInfo['order_by']['usertype'];
    this.prepareOrder['order_by']      = this.orderByOrderInfo['order_by']['id'];
    this.prepareOrder['order_to']      = this.orderByOrderInfo['order_to']['id'];
    this.prepareOrder['order_tax']     = this.orderTax;
    this.prepareOrder['order_amount']  = this.orderAmount;
    this.prepareOrder['order_summary'] = this.orderItemList; 
  }

  addToCart() {
    this.apiHttp.getSingleProductInfoOrder(this.addProductToCart)
    .subscribe(data=> {

      let keys = Object.keys(data);
      if(keys.length != 0) {
        
        if(data['status'] == 100) {

          let productInfoSingle = data['data'];
          this.orderItemList.push(productInfoSingle);
          this.calOrderTotals();
          this.prepareOrderUpdate();
        
        } else if(data['status'] == 103) {
          this.apiHttp.logoutUser();
        }
      }

    });
  }

  calOrderTotals() {
    this.removeDuplicates();
    this.orderAmount = 0.0;
    this.orderTax = 0.0;

    // console.log("List"+this.orderItemList);
    this.orderItemList.forEach(singleItem=>{
      this.orderAmount = this.orderAmount+singleItem['amount'];
      this.orderTax    = this.orderTax+singleItem['taxAmount'];
      console.log( this.orderAmount);

    });
  }

  removeDuplicates() {

    let originalArray = this.orderItemList;
    let prop = "pid";

    var newArray = [];
    var lookupObject  = {};

    for(var i in originalArray) {
       lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for(i in lookupObject) {
        newArray.push(lookupObject[i]);
    }
    
    this.orderItemList = newArray;
  }

  removeItem(index) {
    this.orderItemList.splice(index,1);
    this.calOrderTotals();
    this.prepareOrderUpdate();
  }

}

@Component({
  selector: 'dialog-order-checkout',
  templateUrl: 'dialog-order-checkout.html',
})
export class openOrderCheckoutDialog {

  prepareOrder = {order_amount:0.0,order_tax:0.0,order_summary:[]};
  constructor(
    public dialogRef: MatDialogRef<openOrderCheckoutDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private apiHttp:ApiHttpService,private snackBar: MatSnackBar,private router: Router
    ) {
       this.prepareOrder = data['prepareOrder'];

    }

    confirmCheckout() {

      this.apiHttp.placeOrder(this.prepareOrder)
      
      .subscribe(data=> {
  
        let keys = Object.keys(data);
        if(keys.length != 0) {
          
          if(data['status'] == 100) {
  
            this.snackBar.open("Order booked successfully.!",'', {
              duration: 10000,
              horizontalPosition: 'center',
              verticalPosition : 'top'
            });
            
            this.dialogRef.close();
            let rURL = "admin/order";
            this.router.navigateByUrl(rURL);
  
          } else if(data['status'] == 103) {
            this.apiHttp.logoutUser();
          }
        }
  
      });
    }
  

  }


